import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Input, Button } from "@mui/material";
import PhoneIcon from "@mui/icons-material/Phone";
import { useTranslation } from 'react-i18next';

import "./LogForgetpassword.css";

const LogForgetpassword = () => {
    const navigate = useNavigate();
    const { t } = useTranslation("global");
    const [loading, setLoading] = useState(false);

    const handlePasswordReset = async (e) => {
        e.preventDefault();
        const resetPhone = e.target.passwordRest.value;
        setLoading(true);

        try {
            const response = await fetch("https://lahalak.sa/backend/auth/reset_password_request.php", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    phone: resetPhone,
                }),
            });

            const data = await response.json();

            if (data.status === "success") {
                alert(t("LogForgetpassword.AlertSuccess"));
                navigate("/login");
            } else {
                alert(data.message || t("LogForgetpassword.AlertFail"));
            }
        } catch (error) {
            console.error("Password reset error:", error);
            alert(t("LogForgetpassword.AlertError"));
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="bas">
            <div className="login-type">
                <div className="titel">
                    <h1>{t("LogForgetpassword.Recovery")}</h1>
                </div>
                <form onSubmit={handlePasswordReset}>
                    <div>
                        <label htmlFor="passwordRest">{t("LogForgetpassword.EnterPhone")}</label>
                        <Input
                            type="tel"
                            id="passwordRest"
                            name="passwordRest"
                            placeholder={t("LogForgetpassword.PhonePlaceholder")}
                            required
                            inputProps={{ maxLength: 10, pattern: "^05\\d{8}$" }} // Saudi phone format validation
                        />
                    </div>
                    <div style={{ margin: "5px 0" }}>
                        <Button
                            variant="outlined"
                            endIcon={<PhoneIcon />}
                            type="submit"
                            disabled={loading}
                        >
                            {loading ? t("LogForgetpassword.Loading") : t("LogForgetpassword.SendPhone")}
                        </Button>
                    </div>
                </form>
                <div className="registration-section">
                    <p>{t("LogForgetpassword.NoAccount")}</p>
                    <Link to={"/signup"}>
                        <Button variant="outlined" color="primary">
                            {t("LogForgetpassword.RegisterHere")}
                        </Button>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default LogForgetpassword;
