import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import locationIcon from '../components/images/location.png';
import './HospitalServiceAdd.css';
import { Link } from 'react-router-dom';

const HospitalServiceAdd = () => {
    const { t, i18n } = useTranslation("global");
    const [hospitalServicesData, setHospitalServicesData] = useState(null);
    const [additionalServices, setAdditionalServices] = useState(null); // State to hold additional services data
    const location = useLocation();
    const { id, image } = location.state;
    const serviceId = parseInt(id);
    const getDirection = () => i18n.language === "ar" ? "rtl" : "ltr";

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const loadData = async () => {
            try {
                const dataUrl = 'https://lahalak.sa/backend/getAdditionalServices/getServicesAndHospitals.php';

                const response = await fetch(dataUrl);

                if (!response.ok) {
                    throw new Error(`Failed to fetch data: ${response.statusText}`);
                }

                const data = await response.json();

                // Log the entire data object for debugging
                console.log('Loaded data:', data);

                // Assuming `hospital_services` and `additional_services` are part of the response
                if (data.hospital_services && data.additional_services) {
                    setHospitalServicesData(data.hospital_services); // Hospital data
                    setAdditionalServices(data.additional_services); // Additional services data
                } else {
                    console.warn('Unexpected data format:', data);
                }
            } catch (error) {
                console.error('Error loading data:', error);
            }
        };

        loadData();
    }, [i18n.language]);


    if (!hospitalServicesData || !additionalServices) {
        return <div className="hospital-service-add">{t("HospitalServiceAdd.loading")}</div>;  // Loading indicator
    }

    // Add logging to check the data you're working with
    console.log('serviceId:', serviceId); // Check if serviceId is correctly parsed
    console.log('hospitalServicesData:', hospitalServicesData); // Check data format for hospitals

    const matchingHospitals = hospitalServicesData.filter(hospital => {
        const hospitalServiceIds = hospital.serviceIds
            ? hospital.serviceIds.split(',').map(id => {
                const cleanId = id.trim().replace(/['"]/g, ''); // Remove quotes and trim spaces
                const numericId = Number(cleanId); // Convert to number

                console.log(`Converting serviceId "${cleanId}" to number:`, numericId);

                return isNaN(numericId) ? null : numericId; // Return null if conversion fails
            }).filter(id => id !== null)
            : [];

        // Compare against serviceId
        return hospitalServiceIds.includes(serviceId);
    });

    console.log('matchingHospitals:', matchingHospitals);




    if (matchingHospitals.length === 0) {
        return <div className="hospital-service-add">{t("HospitalServiceAdd.serviceNotFound")}</div>;
    }

    return (
        <div className="hospital-service-add" dir={getDirection()}>
            {matchingHospitals.map((hospital) => {
                let services = {};

                try {
                    services = JSON.parse(hospital.services);
                } catch (e) {
                    console.error("Error parsing services JSON:", e);
                    return null;
                }

                return (
                    <div key={hospital.hospitalName} className="hospital-card">
                        <div className="location-container">
                            <img src={hospital.logo} alt="Hospital Logo" className="hospital-logo" />
                            <div className='column'>
                                <h1 className="hospital-name">{hospital.hospitalName}</h1>
                                <p className="hospital-subtitle">{hospital.subtitle}</p>
                            </div>
                            <div className="locofe">
                                <img src={locationIcon} alt="Location" className="location-icon" />
                                <p className="location-text">{hospital.location}</p>
                            </div>
                        </div>

                        <div className="valueAdded">
                            <h2 className='whiteC'>{t("HospitalServiceAdd.addedValues")}</h2>
                        </div>

                        {services.bookingDetails && services.bookingDetails.map((detail, detailIndex) => (
                            <ul key={detailIndex} className="service-options">
                                {Object.keys(detail).map((key, index) => (
                                    <li key={index}>
                                        <p className="optionsitem">
                                            <span style={{ fontSize: '34px' }}>•</span>{detail[key]}
                                        </p>
                                    </li>
                                ))}
                            </ul>
                        ))}

                        <div className="service-options">
                            <div className="valueAdded">
                                <h2 className='whiteC'>{t("HospitalServiceAdd.availableOptions")}</h2>
                            </div>
                            <ul>
                                {services.options && services.options.map((option, optionIndex) => (
                                    <li key={optionIndex} className="option-item">
                                        {option.optionName1 || option.optionName} - {option.price} {t("HospitalServiceAdd.currency")}
                                        <button className="blueTkhile">
                                            <Link to="/Service_Details" state={{ 
                                                id: serviceId,
                                                price: option.price,
                                                optionName: option.optionName1 || option.optionName,
                                                hospitalName: hospital.hospitalName,
                                                hospitalbranche: hospital.subtitle,
                                                hospitalLocation: hospital.location,
                                                idReal: hospital.id,
                                                image: image,
                                                bookingDetails: services.bookingDetails,
                                                sections: additionalServices.find(service => service.service_id === serviceId.toString())?.sections, // Find and pass sections
                                                useContent1: option.optionName1 !== undefined // Determine if we should use content1
                                            }}>
                                                <p className='small' style={{ color: 'white' }}>{t("HospitalServiceAdd.viewDetails")}</p>
                                            </Link>
                                        </button>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default HospitalServiceAdd;
