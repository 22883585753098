import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import locationIcon from '../components/images/location.png';
import './HospitalServiceAdd.css';
import { Link } from 'react-router-dom';

const Service_provider = () => {
    const { t, i18n } = useTranslation("global");
    const [hospitalServicesData, setHospitalServicesData] = useState(null);
    const [additionalServices, setAdditionalServices] = useState(null);
    const location = useLocation();
    const { id, image } = location.state;
    const serviceId = id.toString(); // Ensure the id is a string for comparison

    const getDirection = () => i18n.language === "ar" ? "rtl" : "ltr";

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const loadData = async () => {
            try {
                // Fetch the hospital services data based on the current language
                const hospitalServicesUrl = i18n.language === "ar"
                    ? 'https://lahalak.sa/backend/json_files/translation/Ar/Add_on_servicesHospital.json'
                    : 'https://lahalak.sa/backend/json_files/translation/En/Add_on_servicesHospital.json';
                
                const hospitalServicesResponse = await fetch(hospitalServicesUrl);
                const hospitalServicesJson = await hospitalServicesResponse.json();
                setHospitalServicesData(hospitalServicesJson);

                // Fetch the additional services data
                const additionalServicesResponse = await fetch('https://lahalak.sa/backend/json_files/translation/Ar/additionalServices.json');
                const additionalServicesJson = await additionalServicesResponse.json();
                setAdditionalServices(additionalServicesJson);

            } catch (error) { 
                console.error('Error loading data:', error);
            }
        };
        loadData();
    }, [i18n.language]);

    if (!hospitalServicesData || !additionalServices) {
        return <div className="hospital-service-add">{t("HospitalServiceAdd.loading")}</div>;
    }

    const matchingHospitals = hospitalServicesData.hospitals.filter(hospital =>
        hospital.serviceIds.includes(parseInt(serviceId))
    );

    if (matchingHospitals.length === 0) {
        return <div className="hospital-service-add">{t("HospitalServiceAdd.serviceNotFound")}</div>;
    }

    const service = additionalServices.find(service => service.id === serviceId);
    const sections = service?.sections || [];

    return (
        <div className="hospital-service-add" dir={getDirection()}>
            {matchingHospitals.map((hospital) => (
                <div key={hospital.hospitalName} className="hospital-card">
                    <div className="location-container">
                        <img src={hospital.logo} alt="Hospital Logo" className="hospital-logo" />
                        <div className='column'>
                            <h1 className="hospital-name">{hospital.hospitalName}</h1>
                            <p className="hospital-subtitle">
                                {hospital.branch}
                            </p>
                        </div>
                        <div className="locofe">
                            <img src={locationIcon} alt="Location" className="location-icon" />
                            <p className="location-text">{hospital.location}</p>
                        </div>
                    </div>

                    <div className="valueAdded">
                        <h2 className='whiteC'>{t("HospitalServiceAdd.addedValues")}</h2>
                    </div>

                    {hospital.services.map((service, serviceIndex) => (
                        <div key={serviceIndex} className="service">
                            <ul className='service-options'>
                                {service.bookingDetails.map((detail, detailIndex) => (
                                    <div key={detailIndex} className="booking-details">
                                        {Object.keys(detail).map((key, index) => (
                                            <li key={index}>
                                                <p className="optionsitem">
                                                    <span style={{ fontSize: '34px' }}>•</span>{detail[key]}
                                                </p>
                                            </li>
                                        ))}
                                    </div>
                                ))}
                            </ul>
                            <div className="service-options">
                                <div className="valueAdded">
                                    <h2 className='whiteC'>{t("HospitalServiceAdd.availableOptions")}</h2>
                                </div>
                                <ul>
                                    {service.options.map((option, optionIndex) => (
                                        <li key={optionIndex} className="option-item">
                                            {option.optionName1 || option.optionName} - {option.price} {t("HospitalServiceAdd.currency")}
                                            <button className="blueTkhile">
                                                <Link to="/ServiceAdd_Details" state={{
                                                    id: serviceId,
                                                    price: option.price,
                                                    optionName: option.optionName1 || option.optionName,
                                                    hospitalName: hospital.hospitalName,
                                                    hospitalLocation: hospital.location,
                                                    image: image,
                                                    bookingDetails: service.bookingDetails,
                                                    serviceDetails: option.serviceDetails, // Pass the service details
                                                    sections: sections, // Pass sections
                                                    useContent1: option.optionName1 !== undefined // Determine if we should use content1
                                                }}>
                                                    <p style={{ color: 'white' }}>{t("HospitalServiceAdd.viewDetails")}</p>
                                                </Link>
                                            </button>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
};

export default Service_provider;
