import React, { useEffect, useState, useRef } from "react";
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { useAuthContext } from '../context/AuthContext';
import { useTranslation } from 'react-i18next';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import 'flag-icons/css/flag-icons.min.css';
import './Navbare.css'; 
import { useNavigate } from 'react-router-dom';

const Navbar = () => {
  const { user } = useAuthContext();
  const { t, i18n } = useTranslation("global");
  const [nav, setNav] = useState(false);
  const navigate = useNavigate();

  const [isConverted, setIsConverted] = useState(i18n.language === 'ar');
  const navRef = useRef();
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredServices1, setFilteredServices1] = useState([]);
  const [debouncedQuery, setDebouncedQuery] = useState(searchQuery);
  const [additionalServices1, setAdditionalServices1] = useState([]);

  const handleNav = () => setNav(!nav);
  const closeNav = () => setNav(false);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const dropdownRef = useRef(null); // Ref to track the dropdown
  const handleServiceClick = (service) => {
    if (service && service.service_id) {
      navigate(`/DetailsAdd?id=${service.service_id}`);
    } else {
      console.error("Service ID is missing");
    }
  };
  const handleLanguageChange = (languageCode) => {
    i18n.changeLanguage(languageCode)
      .then(() => {
        console.log(`Language changed to ${languageCode}`);
        setIsConverted(languageCode === 'ar');
      })
      .catch((error) => {
        console.error('Error changing language:', error);
      });

    closeNav();
  };
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedQuery(searchQuery);
    }, 300);

    return () => {
      clearTimeout(handler);
    };
  }, [searchQuery]);
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (nav && navRef.current && !navRef.current.contains(e.target)) {
        setNav(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [nav]);
  useEffect(() => {
    const loadData1 = async () => {
      try {
        const response1 = await fetch(process.env.REACT_APP_API_URL);
        const data1 = await response1.json();

        if (Array.isArray(data1)) {
          setAdditionalServices1(data1);
        } else {
          console.error("Data1 is not in the expected format (array)");
          setAdditionalServices1([]);
        }
      } catch (error) {
        console.error("Error loading additional services data:", error);
      }
    };

    loadData1();
  }, []);
  const getDirection = () => (i18n.language === "ar" ? "rtl" : "ltr");
  const LanguageDropdown = () => {
    const [isOpen, setIsOpen] = useState(false);
    const languages = [
      { code: 'en', name: 'English', flagClass: 'fi fi-us' }, 
      { code: 'ar', name: 'Arabic', flagClass: 'fi fi-sa' }  
    ];

    const selectedLanguage = languages.find(lang => lang.code === i18n.language);
  
    const handleLanguageSelect = (language) => {
      handleLanguageChange(language.code);
      setIsOpen(false);
    };

    return (
      <div className="dropdown">
        <button className="dropdown-btn" onClick={() => setIsOpen(!isOpen)}>
          <span className={selectedLanguage.flagClass} style={{ marginRight: '8px' }}></span>
          {selectedLanguage.name}
        </button>

        {isOpen && (
          <ul className="dropdown-menu">
            {languages.map(language => (
              <li
                key={language.code}
                className="dropdownitem"
                onClick={() => handleLanguageSelect(language)}
              >
                <span className={language.flagClass} style={{ marginRight: '8px' }}></span> {language.name}
              </li>
            ))}
          </ul>
        )}
      </div>
    );
 
  };
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setIsDropdownVisible(true); // Show the dropdown when typing
  };
  return (
    <div ref={navRef} className='navbare flex justify-between items-center max-w-[1240px] mx-auto px-4 text-white relative z-10' style={{ direction: getDirection() }}>
      <div style={{ display: 'flex' }}>
      <h1 className='text-3xl font-bold text-[#00df9a]'>
          <Link to="/" style={{ display: 'flex', alignItems: 'center' }}>
            <img
              src="images/loba.png"
              alt="Description of the image"
              className="navbar-logo"
            />
          </Link>
        </h1>
        <div className="triabel">{i18n.language === 'ar' ? 'افتتاح تجريبي' : 'Soft Launch'}</div>
      </div>

      <ul className='hidden md:flex' style={{ fontSize: '18px' }}>
        <li className='p-3'>
          <LanguageDropdown />
        </li>
        <li className='p-3'>
          <Link to={user ? "/Home" : "/signup"} style={{ color: 'white' }} onClick={closeNav}>
            <button className={`buttontr ${isConverted ? 'blackButton' : ''}`}>
              <PersonOutlineIcon />
              <p className="redcoffe">{user ? t("Navbar.login2") : t("Navbar.login1")}</p>
            </button>
          </Link>
        </li>
      </ul>

      <div onClick={handleNav} className='block md:hidden'>
        {nav ? <AiOutlineClose size={20} /> : <AiOutlineMenu size={20} />}
      </div>








      {/* <div className="postthing">
        <div style={{ direction: getDirection() }}>
          <div className="search-bar-wrapper">
            <div>
              <div className="new-input-container">
                <div className="input-group">
                  <input
                    type="text"
                    placeholder={t("Gorfe.search_placeholder")}
                value={searchQuery}
                onChange={handleSearchChange}
                className="search-input professional-input"
                aria-label={t("Gorfe.search_placeholder")}
              />
            </div>
          </div>
        </div>

        {searchQuery && isDropdownVisible && filteredServices1.length > 0 && (
          <div className="dropdown-results" ref={dropdownRef}>
          
            {filteredServices1.map(service => (
              <div
                key={service.service_id}
                className="dropdown-item"
                onClick={() => handleServiceClick(service)}
              >
                <div className="item-content">
                  <img src={service.image} alt={service.title} className="service-image" />
                  <span className="service-title">{service.title}</span>
                </div>
              </div>
            ))}
          </div>
        )}

        {searchQuery && filteredServices1.length === 0 && (
          <p className="no-results-message">{t("services.no_services_found")}</p>
        )}
      </div>
    </div>
    </div> */}









      <ul className={nav ? 'fixed left-0 top-0 w-[60%] h-full border-r border-r-gray-900 bg-[#072C7B] ease-in-out duration-500 z-20' : 'ease-in-out duration-500 fixed left-[-100%]'} style={{ fontSize: '16px' }}>
        {/* <div className="logo-container">
          <Link to="/" onClick={closeNav}>
            <img src={`images/loba-${i18n.language}.png`} alt="Logo" className="navbar-logo" style={{ height: 'auto', width: '50px' }} />
          </Link>
        </div> */}
         <div className="logo-container">
          <Link to="/" onClick={closeNav}>
            <img
              src="images/loba.png"
              alt="Description of the image"
              className="navbar-logo"
              style={{ height: 'auto', width: '50px' }}
            />
          </Link>
        </div>
        <li className='p-3'>
          <LanguageDropdown />
        </li>

        <li className='p-4'>
          <button className={`buttontr ${isConverted ? 'blackButton' : ''}`}>
            <Link to={user ? "/Home" : "/signup"} style={{ color: 'white' }} onClick={closeNav}>
              <PersonOutlineIcon /> {user ? t("Navbar.login2") : t("Navbar.login1")}
            </Link>
          </button>
        </li>
      </ul>
    </div>
  );
};

export default Navbar;



// import React, { useEffect, useState, useRef } from "react";
// import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';
// import { Link } from 'react-router-dom';
// import { useAuthContext } from '../context/AuthContext';
// import { useTranslation } from 'react-i18next';

// import './Navbare.css';
// import LanguageIcon from '@mui/icons-material/Language';
// import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
// import LanguageIcon from '@mui/icons-material/Language';

// const Navbar = () => {
//   const { user } = useAuthContext();
//   const { t, i18n } = useTranslation("global"); // Initialize the translation hook
//   const [nav, setNav] = useState(false);
//   const [isConverted, setIsConverted] = useState(false);
//   const navRef = useRef(); // Create a ref for the navbar

//   const handleNav = () => {
//     setNav(!nav);
//   };

//   const closeNav = () => {
//     setNav(false);
//   };
//   const handleLanguageChange = (event) => {
//     const selectedLanguage = event.target.value;
//     const newLanguageCode = selectedLanguage === 'Arabic' ? 'ar' : 'en'; // Map language to the language code
//     i18n.changeLanguage(newLanguageCode)
//       .then(() => {
//         console.log(`Language changed to ${newLanguageCode}`);
//       })
//       .catch((error) => {
//         console.error('Error changing language:', error);
//       });

//     setIsConverted(selectedLanguage === 'Arabic');
//     closeNav();
//   };

//   const handleClick = () => {

//     const newLanguage = isConverted ? 'ar' : 'en'; // Toggle between 'en' and 'ar'
//     i18n.changeLanguage(newLanguage)
//       .then(() => {
//         console.log(`Language changed to ${newLanguage}`);
//       })
//       .catch((error) => {
//         console.error('Error changing language:', error);
//       });
//     setIsConverted(!isConverted);
//     closeNav();
//   };
//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, []);
//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, []);



//   useEffect(() => {
//     const checkIfClickedOutside = e => {
//       if (nav && navRef.current && !navRef.current.contains(e.target)) {
//         setNav(false);
//       }
//     };

//     document.addEventListener("mousedown", checkIfClickedOutside);

//     return () => {
//       document.removeEventListener("mousedown", checkIfClickedOutside);
//     };
//   }, [nav]);



//   const getDirection = () => {
//     return i18n.language === "ar" ? "rtl" : "ltr";
//   };

//   return (
//     <div ref={navRef} className='  navbare flex justify-between items-center max-w-[1240px] mx-auto px-4 text-white relative z-10' style={{ direction: getDirection() }}>
//       <div style={{ display: 'flex' }}>
        // <h1 className='text-3xl font-bold text-[#00df9a]'>
        //   <Link to="/" style={{ display: 'flex', alignItems: 'center' }}>
        //     <img
        //       src="images/loba.png"
        //       alt="Description of the image"
        //       className="navbar-logo"
        //     />
        //   </Link>
        // </h1>
//         <div className="triabel">افتتاح تجريبي</div>

//       </div>

//       <ul className='hidden md:flex' style={{ fontSize: '18px' }}>
//         {/* <li className='p-3'>
//           <Link to="/Home_page" style={{ color: 'white' }}>
//             {t("Navbar.Home_page")}
//           </Link>
//         </li> */}
//         {/* <li className='p-6'>
//           <Link to="/" style={{ color: 'white' }} onClick={closeNav}>
//             {t("Navbar.Shop")}

//           </Link>
//         </li> */}

//         {/* <li className='p-6'>
//           <Link to="/automation" style={{ color: 'white' }}>
//             {t("Navbar.Solutions")}
//           </Link>
//         </li> */}
//         {/* <li className='p-6'>
//           <Link to="/Buying_guide" style={{ color: 'white' }} onClick={closeNav}>
//             {t("Navbar.dalilshira")}

//           </Link>
//         </li> */}
//         {/* <li className='p-6'>
//           <Link to="/Latestnews" style={{ color: 'white' }}>
//             {t("Navbar.News")}
//           </Link>
//         </li> */}

//         {/* <li className='p-6'>
//           <Link to="/support" style={{ color: 'white' }}>
//             {t("Navbar.Support")}
//           </Link>
//         </li> */}


//         {/* <li className='p-6'>
//           <Link to="/contact_us" style={{ color: 'white' }}>
//             {t("Navbar.Contact")}
 
//           </Link>
//         </li> */}

//         {/* <li className='p-4'>
//           <Link to="/ourclient" style={{ color: 'white' }}>
//             {t("Navbar.Client")}

//           </Link>
//         </li> */}


//         <div className="hidden md:flex moreCloser">
//           {/* <li className='p-3'>
//             <button className={`buttontr ${isConverted ? 'blackButton' : ''}`} onClick={handleClick}>
//               <LanguageIcon /> <p className="redcoffe"> {isConverted ? 'Arabic' : 'Eng'}</p>
//             </button>
//           </li> */}

//           <li className='p-3'>
//             <select
//               className="language-dropdown"
//               value={isConverted ? 'Arabic' : 'English'}
//               onChange={handleLanguageChange}
//             >
//               <option value="English">
//                 🇺🇸 English
//               </option>
//               <option value="Arabic">
//                 🇸🇦 Arabic
//               </option>
//             </select>
//           </li>

//           <li className='p-3'>
//             <Link to={user ? "/Home" : "/signup"} style={{ color: 'white' }} onClick={closeNav}>
//               <button className={`buttontr ${isConverted ? 'blackButton' : ''}`}>
//                 <PersonOutlineIcon /><p className="redcoffe"> {user ? t("Navbar.login2") : t("Navbar.login1")}</p>
//               </button>
//             </Link>
//           </li>
//         </div>

//         {/* <li className='p-4'>
//           <button>
//             <img
//               src="images/3455.png"
//               alt="Description of the image"
//               className="mr-4"
//             />
//           </button>
//         </li> */}
//       </ul>

//       <div onClick={handleNav} className='block md:hidden'>
//         {nav ? <AiOutlineClose size={20} /> : <AiOutlineMenu size={20} />}
//       </div>

//       <ul className={nav ? 'fixed left-0 top-0 w-[60%] h-full border-r border-r-gray-900 bg-[#072C7B] ease-in-out duration-500 z-20' : 'ease-in-out duration-500 fixed left-[-100%]'} style={{ fontSize: '16px' }}  >
//         <br />
//         <br />
        // <div className="logo-container">
        //   <Link to="/" onClick={closeNav}>
        //     <img
        //       src="images/loba.png"
        //       alt="Description of the image"
        //       className="navbar-logo"
        //       style={{ height: 'auto', width: '50px' }}
        //     />
        //   </Link>

        // </div>
//         <br />
//         <br />
//         <br />
//         <br />

//         {/* <li className='p-4'>
//           <Link to="/Home_page" style={{ color: 'white' }} onClick={closeNav}>
//             {t("Navbar.Home_page")}

//           </Link>
//         </li> */}
//         {/* <li className='p-4'>
//           <Link to="/" style={{ color: 'white' }} onClick={closeNav}>
//             {t("Navbar.Shop")}

//           </Link>
//         </li> */}
//         {/* 
//         <li className='p-4'>
//           <Link to="/automation" style={{ color: 'white' }} onClick={closeNav}>
//             {t("Navbar.Solutions")}
//           </Link>
//         </li> */}
//         {/* <li className='p-4'>
//           <Link to="/Buying_guide" style={{ color: 'white' }} onClick={closeNav}>
//             {t("Navbar.dalilshira")}

//           </Link>
//         </li> */}
//         {/* <li className='p-4'>
//           <Link to="/Latestnews" style={{ color: 'white' }} onClick={closeNav}>
//             {t("Navbar.News")}

//           </Link>
//         </li> */}
//         {/* <li className='p-4'>
//           <Link to="/support" style={{ color: 'white' }} onClick={closeNav}>
//             {t("Navbar.Support")}

//           </Link>
//         </li> */}




//         {/* <li className='p-4'>
//           <Link to="/contact_us" style={{ color: 'white' }} onClick={closeNav}>
//             {t("Navbar.Contact")}

//           </Link>
//         </li> */}

//         {/* <li className='p-4'>
//           <Link to="/ourclient" style={{ color: 'white' }} onClick={closeNav}>
//             {t("Navbar.Client")}
//           </Link>
//         </li> */}


//         <div>
//           {/* <li className='p-4'>
//             <button className={`buttontr ${isConverted ? 'blackButton' : ''}`}
//               onClick={handleClick}>
//               <LanguageIcon /> {isConverted ? 'Arb' : 'Eng'}
//             </button>
//           </li> */}
//           <li className='p-3'>
//             <select
//               className="language-dropdown"
//               value={isConverted ? 'Arabic' : 'English'}
//               onChange={handleLanguageChange}
//             >
//               <option value="English">🇺🇸 English</option>
//               <option value="Arabic">🇸🇦 Arabic</option>
//             </select>
//           </li>


//           <li className='p-4'>
//             <button className={`buttontr ${isConverted ? 'blackButton' : ''}`}
//             >
//               <Link to={user ? "/Home" : "/signup"} style={{ color: 'white' }} onClick={closeNav}>
//                 <PersonOutlineIcon /> {user ? t("Navbar.login2") : t("Navbar.login1")}
//               </Link>
//             </button>
//           </li>
//           {/* <li className='p-4'>
//             <Link to={user ? "/home" : "/signup"} style={{ color: 'white' }} onClick={closeNav}>
//               <button className={`buttontr ${isConverted ? 'blackButton' : ''}`} >
//                 <PersonOutlineIcon /> {user ? 'profile' : 'login'}
//               </button>
//             </Link>
//           </li> */}
//           {/* <li className='p-4'>
//             <button>
//               <img
//                 src="images/3455.png"
//                 alt="Description of the image"
//                 className="mr-4"
//               />
//             </button>
//           </li> */}

//           {/* <Link to="/" onClick={closeNav}>

//           </Link> */}
//         </div>
//       </ul>
//     </div>
//   );
// };

// export default Navbar;
