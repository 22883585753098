import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { createPortal } from "react-dom";
import { HomePasswordChangeModal, HomeWithdrawalChild } from "./HomeChild";
import { useAuthContext } from "../context/AuthContext";
import Notification from './Notification';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import Title from "./Title";
import { Button, Card, CardContent, Typography } from "@mui/material";
import { Logout } from "@mui/icons-material";
import "./Home.css";
import { FaBell, FaFileMedical, FaShoppingBag, FaFileInvoice, FaQuoteRight, FaCalendarAlt, FaUsers, FaPhoneAlt, FaLink, FaCog } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const ModalPortal = ({ children }) => {
  const target = document.querySelector(".modalContainer");
  return createPortal(children, target);
};

const Home = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t, i18n } = useTranslation("global");
  const { user, setUser } = useAuthContext();
  const [withdrawalModalOpen, setWithdrawalModalOpen] = useState(false);
  const [passwordChangeModalOpen, setPasswordChangeModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [notification, setNotification] = useState({ open: false, message: '', severity: '' });
  const [pdfs, setPdfs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState(null);
  const [otp, setOtp] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const { phone } = location.state || { phone: user?.phone };
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!phone || !otp) {
      setResponseMessage('Phone number and OTP are required for verification!');
      return;
    }

    try {
      const response = await fetch(`https://lahalak.sa/backend/auth/verify_otp.php`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: new URLSearchParams({ otp, phone }),
      });

      const result = await response.json();
      if (result.status === 'success') {
        setResponseMessage('OTP verified successfully!');
        refetchUserData();
        window.location.href = 'https://lahalak.sa';
      } else {
        setResponseMessage(result.message || 'OTP verification failed!');
      }
    } catch (error) {
      setResponseMessage('Error occurred during verification!');
    }
  };

  const refetchUserData = async () => {
    try {
      const response = await fetch(`https://lahalak.sa/backend/home/get_user_data.php?phone=${phone}`, {
        credentials: 'include',
      });
      const data = await response.json();
      setUser(data.user_data);
      setUserData(data.user_data);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  const handleLogout = async () => {
    setIsLoading(true);
    try {
      const response = await fetch('https://lahalak.sa/backend/auth/logout.php', {
        method: 'POST',
        credentials: 'include',
      });
      if (response.ok) {
        setNotification({ open: true, message: t('Home.logoutSuccess'), severity: 'success' });
        setIsLoading(false);
        navigate("/login");
      } else {
        throw new Error('Logout failed');
      }
    } catch (error) {
      setNotification({ open: true, message: t('Home.logoutFailure') + error.message, severity: 'error' });
      setIsLoading(false);
    }
  };

  const handleCloseNotification = () => {
    setNotification({ ...notification, open: false });
  };

  useEffect(() => {
    if (user && user.is_verified) {
      const fetchPdfs = async () => {
        try {
          const response = await fetch(`https://lahalak.sa/backend/get_pdfs.php?phone=${user.phone}`, {
            credentials: 'include',
          });
          const pdfData = await response.json();
          setPdfs(pdfData);
        } catch (error) {
          console.error("Error fetching PDFs:", error);
        } finally {
          setLoading(false);
        }
      };
      fetchPdfs();
    } else {
      setLoading(false);
    }
  }, [user]);

  console.log('Phone:', phone);
  console.log('OTP:', otp);

  useEffect(() => {
    if (user) {
      refetchUserData();
    } else {
      setLoading(false);
    }
  }, [user]);

  useEffect(() => {
    if (user && !user.is_verified) {
      navigate('/verify');
    }
  }, [user, navigate]);

  if (loading) {
    return <div>Loading...</div>;
  }

  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      width: '300px',
      gap: '10px',
    },
    input: {
      padding: '10px',
      fontSize: '16px',
      borderRadius: '4px',
      border: '1px solid #ccc',
    },
    button: {
      padding: '10px',
      backgroundColor: '#072c7b',
      color: 'white',
      fontSize: '16px',
      borderRadius: '4px',
      border: 'none',
      cursor: 'pointer',
    },
  };

  return (
    <div className="home-containerwak" dir={i18n.language === "ar" ? "rtl" : "ltr"}>
      {user && user.is_verified && (
        <>
          <div className="icon-user-container">
            <FaBell />
            <div className="user-name">
              {user ? `${user.firstName} ${user.lastName}` : ''}
            </div>
          </div>
          <div className="navigation-container">
            <div className="navigation-item" onClick={() => navigate("/PersonalInfoForm")}>
              <FaFileMedical />
              <span>{t('Home.completeInfo')}</span>
            </div>
            <div className="navigation-item">
              <FaQuoteRight />
              <span>{t('Home.contracts')}</span>
            </div>
            <div className="navigation-item">
              <FaFileInvoice />
              <span>{t('Home.invoices')}</span>
            </div>
            <div className="navigation-item">
              <FaShoppingBag />
              <span>
                <Link to={"/My_contracts"} style={{ fontSize: '1rem', color: '#333' }}>
                  {t('Home.quotes')}
                </Link>
              </span>
            </div>
            <div className="navigation-item">
              <FaCalendarAlt />
              <span>
                <Link to={"/My_appointments"} style={{ fontSize: '1rem', color: '#333' }}>
                  {t('Home.appointments')}
                </Link>
              </span>
            </div>
            <div className="navigation-item">
              <FaUsers />
              <span>{t('Home.family')}</span>
            </div>
            <div className="navigation-item">
              <FaLink />
              <span>{t('Home.contactUs')}</span>
            </div>
            <div className="navigation-item">
              <FaCog />
              <span>{t('Home.accountSettings')}</span>
            </div>
          </div>
        </>
      )}


      <div className="homeainer">
        <Title />
        <div className="modalContainer"></div>
        <Button onClick={handleLogout} variant="outlined" startIcon={<Logout />} sx={{ mr: 1 }}>
          {t('Home.logoutButton')}
        </Button>
        <Button className="unsubscribe-button" onClick={() => setWithdrawalModalOpen(true)} variant="outlined" sx={{ mr: 1 }}>
          {t('Home.unsubscriptionButton')}
        </Button>
        <Button className="change-password-button" onClick={() => setPasswordChangeModalOpen(true)} variant="outlined" startIcon={<PublishedWithChangesIcon />}>
          {t('Home.changePasswordButton')}
        </Button>
      </div>
      <div className="emergency-call-container">
        <a href="tel:+966571605516" className="emergency-call-button">
          <FaPhoneAlt />
          {t('Home.emergencyCall')}
        </a>
      </div>
      {passwordChangeModalOpen && (
        <ModalPortal>
          <HomePasswordChangeModal setPasswordChangeModalOpen={setPasswordChangeModalOpen} user={user} />
        </ModalPortal>
      )}
      {withdrawalModalOpen && (
        <ModalPortal>
          <HomeWithdrawalChild setWithdrawalModalOpen={setWithdrawalModalOpen} user={user} />
        </ModalPortal>
      )}
      <Notification
        message={notification.message}
        severity={notification.severity}
        open={notification.open}
        handleClose={handleCloseNotification}
      />
    </div>
  );
};

export default Home;
