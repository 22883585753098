import React, { useState, useEffect } from 'react';
import './Calendar.css';
import nextIcon from './images/next-icon.svg';
import prevIcon from './images/prev-icon.svg';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useAuthContext } from '../context/AuthContext';

const generateTimes = () => {
    const times = [];
    const startHour = 10;
    const endHour = 18;
    const interval = 30;

    for (let hour = startHour; hour <= endHour; hour++) {
        for (let minute = 0; minute < 60; minute += interval) {
            const time = hour < 12
                ? `${hour}:${minute === 0 ? '00' : minute} am`
                : `${hour === 12 ? hour : hour - 12}:${minute === 0 ? '00' : minute} pm`;
            times.push(time);
        }
    }

    return times;
};

class Time {
    constructor(date) {
        if (!(date instanceof Date) || isNaN(date)) {
            throw new Error('Invalid Date');
        }
        this.DayOfTheWeek = date.getDay();
        this.Day = date.getDate();
        this.Element = null;
        this.Available = [];
    }
}

class Times {
    constructor(month, year) {
        this.month = month;
        this.year = year;
        this.array = this.getTimesByDate(month, year);
    }

    getTimesByDate(month, year) {
        let date = new Date(year, month, 1);
        let days = [];

        while (date.getMonth() === month) {
            let time = new Time(new Date(date));
            days.push(time);
            date.setDate(date.getDate() + 1);
        }

        const firstDayOfWeek = days[0].DayOfTheWeek;
        const lastDayOfWeek = days[days.length - 1].DayOfTheWeek;

        for (let i = 0; i < firstDayOfWeek; i++) {
            days.unshift(null);
        }

        for (let i = lastDayOfWeek + 1; i < 7; i++) {
            days.push(null);
        }

        return days;
    }

    findTime(day) {
        return this.array.find(time => time?.Day === parseInt(day, 10));
    }
}

const loadInData = (times) => {
    const calendar = times.array;
    const daysWithTimes = generateTimes();

    calendar.forEach(day => {
        if (day && day.DayOfTheWeek !== 5) { // Exclude Fridays
            day.Available = daysWithTimes;
        }
    });
};

const Calendar = () => {
    const location = useLocation();
    const { user } = useAuthContext();

    const [currentMonth, setCurrentMonth] = useState(new Date().getMonth());
    const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
    const [calendarData, setCalendarData] = useState([]);
    const [selectedDayInfo, setSelectedDayInfo] = useState(null);
    const [selectedTimes, setSelectedTimes] = useState([]);
    const [selectedDay, setSelectedDay] = useState(null);
    const [isConfirmed, setIsConfirmed] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const { t } = useTranslation("global");

    const { name, phone, amount, reference_number, email } = location.state || {};

    useEffect(() => {
        const times = new Times(currentMonth, currentYear);
        loadInData(times);
        setCalendarData(times.array);
    }, [currentMonth, currentYear]);

    const handleNextMonth = () => {
        setCurrentMonth(prev => {
            const newMonth = prev + 1 > 11 ? 0 : prev + 1;
            if (newMonth === 0) {
                setCurrentYear(currentYear + 1);
            }
            return newMonth;
        });
    };

    const handlePrevMonth = () => {
        setCurrentMonth(prev => {
            const newMonth = prev - 1 < 0 ? 11 : prev - 1;
            if (newMonth === 11) {
                setCurrentYear(currentYear - 1);
            }
            return newMonth;
        });
    };

    const handleDayClick = (day) => {
        if (isDateInPast(day.Day)) return;
        setSelectedDayInfo({
            date: `${t(`months.${currentMonth}`)} ${day.Day}, ${currentYear}`,
            dayOfTheWeek: t(`weekDays.${new Date(currentYear, currentMonth, day.Day).getDay()}`),
            availableTimes: day.Available || []
        });
        setSelectedTimes([]);
        setSelectedDay(day.Day);
    };

    const handleTimeSelect = (time) => {
        setSelectedTimes([time]); // Single selection
    };

    const isDateInPast = (day) => {
        const today = new Date();
        const selectedDate = new Date(currentYear, currentMonth, day);
        return selectedDate < today.setHours(0, 0, 0, 0);
    };

    const handleConfirmSelection = async () => {
        if (!selectedDayInfo || selectedTimes.length === 0) {
            alert("Please select a date and time.");
            return;
        }
        setIsLoading(true); 
        const convertedPhone = phone.startsWith('05') ? `966${phone.slice(1)}` : phone;

        const formData = new FormData();
        formData.append('name', name);
        formData.append('phone', convertedPhone);
        formData.append('amount', amount);
        formData.append('reference_number', reference_number);
        formData.append('email', email);
        formData.append('selected_date', selectedDayInfo.date);
        formData.append('selected_time', selectedTimes[0]);

        try {
            const response = await fetch('https://lahalak.sa/backend/store_payment_info.php', {
                method: 'POST',
                body: formData
            });

            const result = await response.json();
            if (result.success) {
                console.log('Data successfully uploaded:', result);
                setIsConfirmed(true); 
            } else {
                throw new Error(result.message);
            }
        } catch (error) {
            console.error("Error uploading data:", error);
            alert("There was an error processing your request. Please try again.");
        } finally {
            setIsLoading(false); 
        }
    };

    return (
        <div className='backgroundwhiteASS'>
            <div className="calendar2">
                <TopBar />
                <div className="calendar3">
                    <div className="calendar7">
                        <div className="calendar8">
                            <img src={prevIcon} alt={t("previousMonth")} onClick={handlePrevMonth} className="calendar10" />
                            <span className="calendar9">{t(`months.${currentMonth}`)} {currentYear}</span>
                            <img src={nextIcon} alt={t("nextMonth")} onClick={handleNextMonth} className="calendar11" />
                        </div>
                        <br />
                        <CalendarBody
                            calendarData={calendarData}
                            handleDayClick={handleDayClick}
                            isDateInPast={isDateInPast}
                            handleTimeSelect={handleTimeSelect}
                            selectedTimes={selectedTimes}
                            selectedDay={selectedDay}
                        />
                    </div>
                </div>
                <br />
                <div className='centre'>
                    {selectedDayInfo && <InfoTab dayInfo={selectedDayInfo} t={t} selectedTimes={selectedTimes} handleTimeSelect={handleTimeSelect} />}
                </div>
                <br />
                {selectedDayInfo && (
                <div className='centre'>
                    <br />
                    {!isConfirmed ? (
                        <button
                            className="blueNewTkhile"
                            onClick={handleConfirmSelection}
                            disabled={isLoading} 
                        >
                            {isLoading ? (
                                <span>
                                    <i className="fa fa-spinner fa-spin"></i> {t("Calendar.confirmSelection")}
                                </span>
                            ) : (
                                t("Calendar.confirmSelection")
                            )}
                        </button>
                    ) : (
                        <button
                            className="blueNewTkhile"
                            onClick={() => {
                                if (user) {
                                    window.location.href = "/My_appointments";
                                } else {
                                    localStorage.setItem('appointmentInfo', JSON.stringify({
                                        name,
                                        phone,
                                        amount,
                                        reference_number,
                                        email,
                                        selected_date: selectedDayInfo.date,
                                        selected_time: selectedTimes[0]
                                    }));
                                    // Redirect to Signup page
                                    window.location.href = "/signup";
                                }
                            }}
                        >
                            {user ? t("Calendar.goHome") : t("Calendar.signup")}
                        </button>
                    )}

                    {!user && (
                        <div className="kadah1">
                            <div className="kadah2">
                                <div className="kadah3">
                                    <p className="kadah-text">
                                        {t("Calendar.signupmessage")}
                                    </p>
                                </div>
                            </div>
                        </div>
                    )}

                    <br />
                </div>
            )}

                {isConfirmed && (
                    <div className='centre'>
                        <br />
                        <p>{t("Calendar.sessionCompletedInnuendo")}</p> 
                        <br />
                    </div>
                )}
            </div>
        </div>
    );
};

const TopBar = () => (
    <div className="calendar3"></div>
);

const CalendarBody = ({ calendarData, handleDayClick, isDateInPast, handleTimeSelect, selectedTimes, selectedDay }) => (
    <div className="calendar12">
        {calendarData.map((day, dayIdx) =>
            day ? (
                <div
                    key={dayIdx}
                    className={`calendar15 ${isDateInPast(day.Day) ? 'disabled-day' : ''} ${day.DayOfTheWeek === 5 ? 'friday-day' : ''} ${selectedDay === day.Day ? 'selected-day' : ''}`}
                    onClick={() => handleDayClick(day)}
                >
                    <br />
                    <span className="calendar16">{day.Day}</span>
                    <div className={`calendar17 ${day.DayOfTheWeek !== 5 && generateTimes().length > 0 ? 'calendar24' : ''}`}>
                        {day.DayOfTheWeek !== 5 && generateTimes().map((time, idx) => (
                            <div
                                key={idx}
                                className={`calendar18 ${selectedTimes.includes(time) ? 'selected-time' : ''}`}
                            >
                                <br />
                                <input
                                    type="checkbox"
                                    id={`time-${idx}`}
                                    className="custom-checkbox"
                                    checked={selectedTimes.includes(time)}
                                    onChange={() => handleTimeSelect(time)}
                                />
                                <label htmlFor={`time-${idx}`}>{time}</label>
                            </div>
                        ))}
                    </div>
                </div>
            ) : (
                <div key={dayIdx} className="calendar15"></div>
            )
        )}
    </div>
);

const InfoTab = ({ dayInfo, t, selectedTimes, handleTimeSelect }) => {
    const dateParts = dayInfo.date.split(' ');

    const months = {
        January: 0, February: 1, March: 2, April: 3,
        May: 4, June: 5, July: 6, August: 7,
        September: 8, October: 9, November: 10, December: 11,
        يناير: 0, فبراير: 1, مارس: 2, أبريل: 3,
        مايو: 4, يونيو: 5, يوليو: 6, أغسطس: 7,
        سبتمبر: 8, أكتوبر: 9, نوفمبر: 10, ديسمبر: 11
    };

    const monthName = dateParts[0];
    const day = parseInt(dateParts[1].replace(',', ''), 10);
    const year = parseInt(dateParts[2], 10);

    const monthIndex = months[monthName];
    if (monthIndex === undefined || isNaN(day) || isNaN(year)) {
        return (
            <div className="calendar25">
                <div className="calendar26">
                    <span className="calendar27">{dayInfo.date}</span>
                    <span className="calendar28">{dayInfo.dayOfTheWeek}</span>
                    <span className="calendar30-small">{`${t("GregorianDate")} (INVALID DATE)`}</span>
                </div>
                <div className="calendar30">
                    <span className="calendar31">{t("noTimesAvailable")}</span>
                </div>
            </div>
        );
    }

    const formattedDate = new Date(year, monthIndex, day);
    const localizedDateGregorian = formattedDate.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric'
    });

    return (
        <div className="calendar25">
            <div className="calendar26">
                <span className="calendar27">{dayInfo.date}</span>
                <span className="calendar28">{dayInfo.dayOfTheWeek}</span>
                <span className="calendar30-small">{` (${localizedDateGregorian})`}</span>
            </div>
            <div className="calendar30">
                {dayInfo.availableTimes.length > 0 ? (
                    <select
                        className="time-select"
                        onChange={(e) => handleTimeSelect(e.target.value)}
                    >
                        <option value="" disabled selected>{t("SelectTime")}</option>
                        {dayInfo.availableTimes.map((time, idx) => (
                            <option key={idx} value={time} className={`calendar32 ${selectedTimes.includes(time) ? 'selected' : ''}`}>
                                {time}
                            </option>
                        ))}
                    </select>
                ) : (
                    <span className="calendar31">{t("Calendar.noTimesAvailable")}</span>
                )}
            </div>
            {selectedTimes.length > 0 && (
                <div className="calendar33">
                    <span className="selected-time-display">{t("Calendar.selectedTime")}: {selectedTimes.join(', ')}</span>
                </div>
            )}
        </div>
    );
};

export default Calendar;












// import React, { useState, useEffect } from 'react';
// import './Calendar.css';
// import nextIcon from './images/next-icon.svg';
// import prevIcon from './images/prev-icon.svg';
// import { useTranslation } from 'react-i18next';
// import { useLocation } from 'react-router-dom';
// import { getFirestore, collection, query, where, getDocs, updateDoc } from 'firebase/firestore';
// import { initializeApp } from 'firebase/app';
// import { useAuthContext } from '../context/AuthContext';

// const firebaseConfig = {
//     apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
//     authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
//     databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
//     projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
//     storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
//     messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
//     appId: process.env.REACT_APP_FIREBASE_APP_ID,
//     measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
// };

// const app = initializeApp(firebaseConfig);
// const db = getFirestore(app);

// const generateTimes = () => {
//     const times = [];
//     const startHour = 10;
//     const endHour = 18;
//     const interval = 30;

//     for (let hour = startHour; hour <= endHour; hour++) {
//         for (let minute = 0; minute < 60; minute += interval) {
//             const time = hour < 12
//                 ? `${hour}:${minute === 0 ? '00' : minute} am`
//                 : `${hour === 12 ? hour : hour - 12}:${minute === 0 ? '00' : minute} pm`;
//             times.push(time);
//         }
//     }

//     return times;
// };

// class Time {
//     constructor(date) {
//         if (!(date instanceof Date) || isNaN(date)) {
//             throw new Error('Invalid Date');
//         }
//         this.DayOfTheWeek = date.getDay();
//         this.Day = date.getDate();
//         this.Element = null;
//         this.Available = [];
//     }
// }

// class Times {
//     constructor(month, year) {
//         this.month = month;
//         this.year = year;
//         this.array = this.getTimesByDate(month, year);
//     }

//     getTimesByDate(month, year) {
//         let date = new Date(year, month, 1);
//         let days = [];

//         while (date.getMonth() === month) {
//             let time = new Time(new Date(date));
//             days.push(time);
//             date.setDate(date.getDate() + 1);
//         }

//         const firstDayOfWeek = days[0].DayOfTheWeek;
//         const lastDayOfWeek = days[days.length - 1].DayOfTheWeek;

//         for (let i = 0; i < firstDayOfWeek; i++) {
//             days.unshift(null);
//         }

//         for (let i = lastDayOfWeek + 1; i < 7; i++) {
//             days.push(null);
//         }

//         return days;
//     }

//     findTime(day) {
//         return this.array.find(time => time?.Day === parseInt(day, 10));
//     }
// }

// const loadInData = (times) => {
//     const calendar = times.array;
//     const daysWithTimes = generateTimes();

//     calendar.forEach(day => {
//         if (day && day.DayOfTheWeek !== 5) { // Exclude Fridays
//             day.Available = daysWithTimes;
//         }
//     });
// };

// const Calendar = () => {
//     const location = useLocation();
//     const { user } = useAuthContext();

//     const [currentMonth, setCurrentMonth] = useState(new Date().getMonth());
//     const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
//     const [calendarData, setCalendarData] = useState([]);
//     const [selectedDayInfo, setSelectedDayInfo] = useState(null);
//     const [selectedTimes, setSelectedTimes] = useState([]);
//     const [selectedDay, setSelectedDay] = useState(null);
//     const [isConfirmed, setIsConfirmed] = useState(false); // New state for confirming selection
//     const { t } = useTranslation("global"); // Only declare 't' once here
//     const { reference_number } = location.state || {};
//     console.log('Payment Component State:', { reference_number });

//     useEffect(() => { 
//         const times = new Times(currentMonth, currentYear);
//         loadInData(times);
//         setCalendarData(times.array);
//     }, [currentMonth, currentYear]);

//     const handleNextMonth = () => {
//         setCurrentMonth(prev => {
//             const newMonth = prev + 1 > 11 ? 0 : prev + 1;
//             if (newMonth === 0) {
//                 setCurrentYear(currentYear + 1);
//             }
//             return newMonth;
//         });
//     };

//     const handlePrevMonth = () => {
//         setCurrentMonth(prev => {
//             const newMonth = prev - 1 < 0 ? 11 : prev - 1;
//             if (newMonth === 11) {
//                 setCurrentYear(currentYear - 1);
//             }
//             return newMonth;
//         });
//     };
//     const [isLoading, setIsLoading] = useState(false);

//     const handleDayClick = (day) => {
//         if (isDateInPast(day.Day)) return;
//         setSelectedDayInfo({
//             date: `${t(`months.${currentMonth}`)} ${day.Day}, ${currentYear}`,
//             dayOfTheWeek: t(`weekDays.${new Date(currentYear, currentMonth, day.Day).getDay()}`),
//             availableTimes: day.Available || []
//         });
//         setSelectedTimes([]);
//         setSelectedDay(day.Day);
//     };

//     const handleTimeSelect = (time) => {
//         setSelectedTimes([time]); // Single selection
//     };

//     const isDateInPast = (day) => {
//         const today = new Date();
//         const selectedDate = new Date(currentYear, currentMonth, day);
//         return selectedDate < today.setHours(0, 0, 0, 0);
//     };

//     const handleConfirmSelection = async () => {
//         if (!selectedDayInfo || selectedTimes.length === 0) {
//             alert("Please select a date and time.");
//             return;
//         }
//         setIsLoading(true); // Start loading spinner

//         const ordersCollection = collection(db, "orders");
//         const usersCollection = collection(db, "users"); // Reference to the users collection
    
//         try {
//             const orderQuery = query(ordersCollection, where("reference_number", "==", reference_number));
//             const orderSnapshot = await getDocs(orderQuery);
    
//             const userQuery = query(usersCollection, where("phone", "==", user.phone));
//             const userSnapshot = await getDocs(userQuery);
    
//             if (!orderSnapshot.empty) {
//                 const orderDoc = orderSnapshot.docs[0];
//                 const orderDocRef = orderDoc.ref;
    
//                 const dataToUpdate = {
//                     selectedDate: selectedDayInfo.date,
//                     selectedTime: selectedTimes[0]
//                 };
    
//                 await updateDoc(orderDocRef, dataToUpdate);
//                 setIsConfirmed(true); // Set state to true after successful confirmation
//                 setIsConfirmed(true); // Set state to true after successful confirmation
//                 console.log(`Updated order document ${orderDoc.id} with selectedDayInfo, selectedDate, and selectedTime`);
    
//                 if (!userSnapshot.empty) {
//                     const userDoc = userSnapshot.docs[0];
//                     const userDocRef = userDoc.ref;
    
//                     await updateDoc(userDocRef, dataToUpdate);
//                     console.log(`Updated user document ${userDoc.id} with selectedDayInfo, selectedDate, and selectedTime`);
//                 } else {
//                     console.log('No user document found with phone:', user.phone);
//                 }
//             } else {
//                 console.log('No order document found with reference number:', reference_number);
//                 alert("No order found with the given reference number.");
//             }
//         } catch (error) {
//             console.error("Error retrieving or updating document:", error);
//             alert("There was an error retrieving or updating the document. Please try again.");
//         }finally {
//             setIsLoading(false); // Stop loading spinner
//         }
//     };
    

//     return (
//         <div className='backgroundwhiteASS'>
//             <div className="calendar2">
//                 <TopBar />
//                 <div className="calendar3">
//                     <div className="calendar7">
//                         <div className="calendar8">
//                             <img src={prevIcon} alt={t("previousMonth")} onClick={handlePrevMonth} className="calendar10" />
//                             <span className="calendar9">{t(`months.${currentMonth}`)} {currentYear}</span>
//                             <img src={nextIcon} alt={t("nextMonth")} onClick={handleNextMonth} className="calendar11" />
//                         </div>
//                         <br />

//                         <CalendarBody
//                             calendarData={calendarData}
//                             handleDayClick={handleDayClick}
//                             isDateInPast={isDateInPast}
//                             handleTimeSelect={handleTimeSelect}
//                             selectedTimes={selectedTimes}
//                             selectedDay={selectedDay}
//                         />
//                     </div>
//                 </div>
//                 <br />

//                 <div className='centre'>
//                     {selectedDayInfo && <InfoTab dayInfo={selectedDayInfo} t={t} selectedTimes={selectedTimes} handleTimeSelect={handleTimeSelect} />}
//                 </div>
//                 <br />

//                 {selectedDayInfo && (
//     <div className='centre'>
//         <br />
//         {!isConfirmed ? (
//             <button
//                 className="blueNewTkhile"
//                 onClick={handleConfirmSelection}
//                 disabled={isConfirmed} // Disable the button after confirmation
//             >
//                 {isLoading ? (
//                     <span>
//                         <i className="fa fa-spinner fa-spin"></i> {t("Calendar.confirmSelection")}
//                     </span>
//                 ) : (
//                     t("Calendar.confirmSelection")
//                 )}
//             </button>
//         ) : (
//             <button
//                 className="blueNewTkhile"
//                 onClick={() => window.location.href = "/My_appointments"} // Navigate to home page
//             >
//                 {t("Calendar.goHome")}
//             </button>
//         )}
//         <br />
//     </div>
// )}


//                 {isConfirmed && (
//                     <div className='centre'>
//                         <br />
//                         <p>{t("Calendar.sessionCompletedInnuendo")}</p> {/* Small text for session completion */}

//                         <br />
//                     </div>
//                 )}
//             </div>
//         </div>
//     );
// };

// const TopBar = () => (
//     <div className="calendar3"></div>
// );

// const CalendarBody = ({ calendarData, handleDayClick, isDateInPast, handleTimeSelect, selectedTimes, selectedDay }) => (
//     <div className="calendar12">
//         {calendarData.map((day, dayIdx) =>
//             day ? (
//                 <div
//                     key={dayIdx}
//                     className={`calendar15 ${isDateInPast(day.Day) ? 'disabled-day' : ''} ${day.DayOfTheWeek === 5 ? 'friday-day' : ''} ${selectedDay === day.Day ? 'selected-day' : ''}`}
//                     onClick={() => handleDayClick(day)}
//                 >                <br />

//                     <span className="calendar16">{day.Day}</span>
//                     <div className={`calendar17 ${day.DayOfTheWeek !== 5 && generateTimes().length > 0 ? 'calendar24' : ''}`}>
//                         {day.DayOfTheWeek !== 5 && generateTimes().map((time, idx) => (
//                             <div
//                                 key={idx}
//                                 className={`calendar18 ${selectedTimes.includes(time) ? 'selected-time' : ''}`}
//                             >
//                                 <br />

//                                 <input
//                                     type="checkbox"
//                                     id={`time-${idx}`}
//                                     className="custom-checkbox"
//                                     checked={selectedTimes.includes(time)}
//                                     onChange={() => handleTimeSelect(time)}
//                                 />
//                                 <label htmlFor={`time-${idx}`}>{time}</label>
//                             </div>
//                         ))}
//                     </div>
//                 </div>
//             ) : (
//                 <div key={dayIdx} className="calendar15"></div>
//             )
//         )}
//     </div>
// );

// const InfoTab = ({ dayInfo, t, selectedTimes, handleTimeSelect }) => {
//     const dateParts = dayInfo.date.split(' ');

//     const months = {
//         January: 0, February: 1, March: 2, April: 3,
//         May: 4, June: 5, July: 6, August: 7,
//         September: 8, October: 9, November: 10, December: 11,
//         يناير: 0, فبراير: 1, مارس: 2, أبريل: 3,
//         مايو: 4, يونيو: 5, يوليو: 6, أغسطس: 7,
//         سبتمبر: 8, أكتوبر: 9, نوفمبر: 10, ديسمبر: 11
//     };

//     const monthName = dateParts[0];
//     const day = parseInt(dateParts[1].replace(',', ''), 10);
//     const year = parseInt(dateParts[2], 10);

//     const monthIndex = months[monthName];
//     if (monthIndex === undefined || isNaN(day) || isNaN(year)) {
//         return (
//             <div className="calendar25">
//                 <div className="calendar26">
//                     <span className="calendar27">{dayInfo.date}</span>
//                     <span className="calendar28">{dayInfo.dayOfTheWeek}</span>
//                     <span className="calendar30-small">{`${t("GregorianDate")} (INVALID DATE)`}</span>
//                 </div>
//                 <div className="calendar30">
//                     <span className="calendar31">{t("noTimesAvailable")}</span>
//                 </div>
//             </div>
//         );
//     }

//     const formattedDate = new Date(year, monthIndex, day);
//     const localizedDateGregorian = formattedDate.toLocaleDateString('en-US', {
//         year: 'numeric',
//         month: 'numeric',
//         day: 'numeric'
//     });

//     return (
//         <div className="calendar25">
//             <div className="calendar26">
//                 <span className="calendar27">{dayInfo.date}</span>
//                 <span className="calendar28">{dayInfo.dayOfTheWeek}</span>
//                 <span className="calendar30-small">{` (${localizedDateGregorian})`}</span>
//             </div>
//             <div className="calendar30">
//                 {dayInfo.availableTimes.length > 0 ? (
//                     <select
//                         className="time-select"
//                         onChange={(e) => handleTimeSelect(e.target.value)}
//                     >
//                         <option value="" disabled selected>{t("SelectTime")}</option>
//                         {dayInfo.availableTimes.map((time, idx) => (
//                             <option key={idx} value={time} className={`calendar32 ${selectedTimes.includes(time) ? 'selected' : ''}`}>
//                                 {time}
//                             </option>
//                         ))}
//                     </select>
//                 ) : (
//                     <span className="calendar31">{t("Calendar.noTimesAvailable")}</span>
//                 )}
//             </div>
//             {selectedTimes.length > 0 && (
//                 <div className="calendar33">
//                     <span className="selected-time-display">{t("Calendar.selectedTime")}: {selectedTimes.join(', ')}</span>
//                 </div>
//             )}
//         </div>
//     );
// };

// export default Calendar;
