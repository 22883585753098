// import React, { useEffect, useState } from 'react';
// import './WomenServices.css'; 
// import { useTranslation } from 'react-i18next';
// import icon1Img from './components/images/icon12_2.png';
// import icon2Img from './components/images/calander.png';
// import icon3Img from './components/images/hamdanHospital.png';
// import icon4Img from './components/images/icon14.png';
// import Obstetrics_and_GynecologyList from './Obstetrics_and_GynecologyList.js';
// import { Link } from 'react-router-dom';

// function WomenServices() {
//     const { t, i18n } = useTranslation("global");
//     const getDirection = () => {
//         return i18n.language === "ar" ? "rtl" : "ltr";
//     };

//     const [activeScreen, setActiveScreen] = useState('first');
//     const [services, setServices] = useState([]);

//     // Function to change the screen and display related services
//     const handleScreenChange = (screen) => {
//         setActiveScreen(screen);
//     };

//     useEffect(() => {
//         window.scrollTo(0, 0);
//     }, []);

//     const iconData = [
//         {
//             img: icon4Img,
//             title: t("faq.ContactUtitletb33"),
//             text1: t("faq.Contacttxttb331"),
//             text2: t("faq.Contacttxttb332"),
//         },
//         {
//             img: icon3Img,
//             title: t("faq.ContactUtitletb3"),
//             text1: t("faq.Contacttxttb31"),
//             text2: t("faq.Contacttxttb32"),
//         },
//         {
//             img: icon2Img,
//             title: t("faq.ContactUtitletb2"),
//             text1: t("faq.Contacttxttb21"),
//             text2: t("faq.Contacttxttb22"),
//         },
//         {
//             img: icon1Img,
//             title: t("faq.ContactUtitletb1"),
//             text1: t("faq.Contacttxttb11"),
//             text2: t("faq.Contacttxttb12"),
//         },
//     ];

//     // Fetch services based on the active screen
//     useEffect(() => {
//         let url = activeScreen === 'first'
//             ? 'https://lahalak.sa/backend/getAdditionalServices/servicewomenget.php'
//             : 'https://lahalak.sa/backend/getAdditionalServices/pregnancy_follow_up.php';

//         fetch(url)
//             .then(response => response.json())
//             .then(data => {
//                 setServices(data);
//             })
//             .catch(error => {
//                 console.error('Error fetching services:', error);
//             });
//     }, [activeScreen]);

//     return (
//         <div className='womenW'>
//             <Obstetrics_and_GynecologyList />
            
//             <div>
//                 <br />
//                 <br />
//                 <div className='centrPi'>
//                     <h1 className="TitlContwsad">كافة احتياجاتك لتحقيق تجربة ولادة مثالية بكل تفاصيلها</h1>
//                 </div>
//             </div>

//             <div className="cardsContactUw">
//                 {iconData.map((data, index) => (
//                     <div key={index} className="cardfiras">
//                         <div className='rijal'>
//                             <img src={data.img} alt={data.title} />
//                         </div>
//                         <br />
//                         <p className="textCCont">{data.title}</p>
//                         <br />
//                         <p className="texttCont">{data.text1}</p>
//                         <p className="texttCont">{data.text2}</p>
//                     </div>
//                 ))}
//             </div>

//             <div className='desktopdara' style={{ direction: getDirection() }}>
//                 <h1 className="TitlContwsad">خدمات مختارة بعناية</h1>
//                 <h1 className="TitlContwsadS">كل ما تحتاجينه في مكان واحد</h1>
//                 <div className='drze'>
//                     <div className={`button1 ${activeScreen === 'first' ? 'active1' : ''}`}>
//                         <button className={`jawab ${activeScreen === 'first' ? 'jawab1' : ''}`} onClick={() => handleScreenChange('first')}>
//                         كشف
//                         </button>
//                     </div>
//                     <div className={`button2 ${activeScreen === 'second' ? 'active2' : ''}`}>
//                         <button className={`jawab ${activeScreen === 'second' ? 'jawab1' : ''}`} onClick={() => handleScreenChange('second')}>
//                        خدمات المرأة
//                         </button>
//                     </div>
//                     <div className='button3'></div>
//                 </div> 
//                 <br/>
//                 <br/>
//                 <div className='screenContent'>
//                     {activeScreen === 'first' && (
//                         <div>
//                             <div className="desktop-view">
//                                 {Array.isArray(services) && services.map((service, index) => (
//                                     <div className="service-item-desktop" key={index}>
//                                         <Link to={`/DetailsAdd?id=${service.service_id}`}>
//                                             <img className="service-image-desktop" src={service.image} alt="Service Visual Representation" />
//                                             <h5 className="service-title-desktop">{service.title}</h5>
//                                             <p className="service-summary-desktop">{service.summary}</p>
//                                         </Link>
//                                     </div>
//                                 ))}
//                             </div>
//                             <div className="mobile-view">
//                                 {Array.isArray(services) && services.map((service, index) => (
//                                     <div className="service-item-mobile" key={index}>
//                                         <Link to={`/DetailsAdd?id=${service.service_id}`}>
//                                             <img className="service-image-mobile" src={service.image} alt="Service Visual Representation" />
//                                             <h5 className="service-title-mobile">{service.title}</h5>
//                                             <p className="service-summary-mobile">{service.summary}</p>
//                                         </Link>
//                                     </div>
//                                 ))}
//                             </div>
//                         </div>
//                     )}

//                     {activeScreen === 'second' && (
//                         <div>
//                             <div className="desktop-view">
//                                 {Array.isArray(services) && services.map((service, index) => (
//                                     <div className="service-item-desktop" key={index}>
//                                         <Link to={`/DetailsAdd?id=${service.service_id}`}>
//                                             <img className="service-image-desktop" src={service.image} alt="Service Visual Representation" />
//                                             <h5 className="service-title-desktop">{service.title}</h5>
//                                             <p className="service-summary-desktop">{service.summary}</p>
//                                         </Link>
//                                     </div>
//                                 ))}
//                             </div>
//                             <div className="mobile-view">
//                                 {Array.isArray(services) && services.map((service, index) => (
//                                     <div className="service-item-mobile" key={index}>
//                                         <Link to={`/DetailsAdd?id=${service.service_id}`}>
//                                             <img className="service-image-mobile" src={service.image} alt="Service Visual Representation" />
//                                             <h5 className="service-title-mobile">{service.title}</h5>
//                                             <p className="service-summary-mobile">{service.summary}</p>
//                                         </Link>
//                                     </div>
//                                 ))}
//                             </div>
//                         </div>
//                     )}
//                 </div>
//             </div>
//         </div>
//     );
// }

// export default WomenServices;






















import React, { useEffect, useState } from 'react';
import './WomenServices.css'; 
import { useTranslation } from 'react-i18next';
import icon1Img from './components/images/icon12_2.png';
import icon2Img from './components/images/calander.png';
import icon3Img from './components/images/hamdanHospital.png';
import icon4Img from './components/images/icon14.png';
import Obstetrics_and_GynecologyList from './Obstetrics_and_GynecologyList.js';
import { Link } from 'react-router-dom';

function WomenServices() {
    const { t, i18n } = useTranslation("global");
    const getDirection = () => {
        return i18n.language === "ar" ? "rtl" : "ltr";
    };

    const [activeScreen, setActiveScreen] = useState('first');
    const [services, setServices] = useState([]);

    // Function to change the screen and display related services
    const handleScreenChange = (screen) => {
        setActiveScreen(screen);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const iconData = [
        {
            img: icon4Img,
            title: t("faq.ContactUtitletb33"),
            text1: t("faq.Contacttxttb331"),
            text2: t("faq.Contacttxttb332"),
        },
        {
            img: icon3Img,
            title: t("faq.ContactUtitletb3"),
            text1: t("faq.Contacttxttb31"),
            text2: t("faq.Contacttxttb32"),
        },
        {
            img: icon2Img,
            title: t("faq.ContactUtitletb2"),
            text1: t("faq.Contacttxttb21"),
            text2: t("faq.Contacttxttb22"),
        },
        {
            img: icon1Img,
            title: t("faq.ContactUtitletb1"),
            text1: t("faq.Contacttxttb11"),
            text2: t("faq.Contacttxttb12"),
        },
    ];

    // Fetch services based on the active screen
    useEffect(() => {
        let url = activeScreen === 'first'
            ? 'https://lahalak.sa/backend/getAdditionalServices/servicewomenget.php'
            : 'https://lahalak.sa/backend/getAdditionalServices/pregnancy_follow_up.php';

        fetch(url)
            .then(response => response.json())
            .then(data => {
                setServices(data);
            })
            .catch(error => {
                console.error('Error fetching services:', error);
            });
    }, [activeScreen]);

    return (
        <div className='womenW'>
            <Obstetrics_and_GynecologyList />
            
            <div>
                <br />
                <br />
                <div className='centrPi'>
                    <h1 className="TitlContwsad">كافة احتياجاتك لتحقيق تجربة ولادة مثالية بكل تفاصيلها</h1>
                </div>
            </div>

            <div className="cardsContactUw">
                {iconData.map((data, index) => (
                    <div key={index} className="cardfiras">
                        <div className='rijal'>
                            <img src={data.img} alt={data.title} />
                        </div>
                        <br />
                        <p className="textCCont">{data.title}</p>
                        <br />
                        <p className="texttCont">{data.text1}</p>
                        <p className="texttCont">{data.text2}</p>
                    </div>
                ))}
            </div>

            <div className='desktopdara' style={{ direction: getDirection() }}>
                <h1 className="TitlContwsad">خدمات مختارة بعناية</h1>
                <h1 className="TitlContwsadS">كل ما تحتاجينه في مكان واحد</h1>
                <div className='drze'>
                    <div className={`button1 ${activeScreen === 'first' ? 'active1' : ''}`}>
                        <button className={`jawab ${activeScreen === 'first' ? 'jawab1' : ''}`} onClick={() => handleScreenChange('first')}>
                        كشف
                        </button>
                    </div>
                    <div className={`button2 ${activeScreen === 'second' ? 'active2' : ''}`}>
                        <button className={`jawab ${activeScreen === 'second' ? 'jawab1' : ''}`} onClick={() => handleScreenChange('second')}>
                       خدمات المرأة
                        </button>
                    </div>
                    <div className='button3'></div>
                </div> 
                <br/>
                <br/>
                <div className='screenContent'>
                    {activeScreen === 'first' && (
                        <div>
                            <div className="desktop-view">
                                {Array.isArray(services) && services.map((service, index) => (
                                    <div className="speed2desktop" key={index}>
                                    <Link to={`/DetailsAdd?id=${service.service_id}`}>
                                      <img className="speed4desktop" src={service.image} alt="Service Visual Representation" />
                                      <h5 className="speed4desktop">{service.title}</h5>
                                      <p className="speed5desktop">{service.summary}</p>
                                    </Link>
                                  </div>
                                ))}
                            </div>
                            <div className="mobile-view">
                                {Array.isArray(services) && services.map((service, index) => (
                                       <div className="speed2" key={index}>
                                       <Link to={`/DetailsAdd?id=${service.service_id}`}>
                                         <img className="speed3" src={service.image} alt="Service Visual Representation" />
                                         <h5 className="speed4">{service.title}</h5>
                                         <p className="speed5">{service.summary}</p>
                                       </Link>
                                     </div>
                                ))}
                            </div>
                        </div>
                    )}

                    {activeScreen === 'second' && (
                        <div>
                            <div className="desktop-view">
                                {Array.isArray(services) && services.map((service, index) => (
                                    <div className="speed2desktop" key={index}>
                                    <Link to={`/DetailsAdd?id=${service.service_id}`}>
                                      <img className="speed4desktop" src={service.image} alt="Service Visual Representation" />
                                      <h5 className="speed4desktop">{service.title}</h5>
                                      <p className="speed5desktop">{service.summary}</p>
                                    </Link>
                                  </div>
                                ))}
                            </div>
                            <div className="mobile-view">
                                {Array.isArray(services) && services.map((service, index) => (
                                      <div className="speed2" key={index}>
                                      <Link to={`/DetailsAdd?id=${service.service_id}`}>
                                        <img className="speed3" src={service.image} alt="Service Visual Representation" />
                                        <h5 className="speed4">{service.title}</h5>
                                        <p className="speed5">{service.summary}</p>
                                      </Link>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default WomenServices;
















