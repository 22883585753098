import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Button, Typography, Card, CardContent, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';

const Verify = () => {
  const [verified, setVerified] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    const token = searchParams.get('token');

    const verifyUser = async () => {
      if (token) {
        try {
          const response = await fetch(`/backend/auth/verify.php?token=${token}`);
          const result = await response.json();

          if (response.ok && result.status === 'success') {
            setVerified(true);
          } else {
            setError(t('Verification failed. Please try again.'));
          }
        } catch (err) {
          setError(t('Something went wrong. Please try again later.'));
        } finally {
          setLoading(false);
        }
      } else {
        setError(t('Invalid or missing token.'));
        setLoading(false);
      }
    };

    verifyUser();
  }, [searchParams, t]);

  const handleHomeRedirect = () => {
    navigate('/');
  };

  useEffect(() => {
    if (verified) {
      setTimeout(() => {
        navigate('/');
      }, 3000); // Redirect after 3 seconds if verified
    }
  }, [verified, navigate]);

  if (loading) {
    return (
      <div style={styles.loadingContainer}>
        <CircularProgress />
        <Typography variant="body1" style={{ marginTop: '20px' }}>
          {t('Verifying...')}
        </Typography>
      </div>
    );
  }

  return (
    <div style={styles.container}>
      <Card>
        <CardContent>
          <Typography variant="h5" component="h2">
            {verified ? t('Account Verified!') : t('Verification Failed')}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {verified
              ? t('Your account has been successfully verified. You will be redirected shortly.')
              : error}
          </Typography>
          <Button onClick={handleHomeRedirect} variant="contained" color="primary" style={{ marginTop: '20px' }}>
            {t('Go to Home')}
          </Button>
        </CardContent>
      </Card>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
  },
  loadingContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
  },
};

export default Verify;
