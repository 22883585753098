import React from 'react';
import { useNavigate } from 'react-router-dom';
import { issueMsg } from '../service/common/issueMsg';
import { useTranslation } from 'react-i18next';
import './styles/portal.css';

// Component for deleting the user account
export const HomeWithdrawalChild = ({ setWithdrawalModalOpen, user }) => {
  const { t } = useTranslation("global");
  const navigate = useNavigate();

  const handleDelUserClick = async () => {
    try {
      const response = await fetch('/backend/auth/delete_user.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: user.email }),
      });

      const data = await response.json();
      setWithdrawalModalOpen(false);

      if (data.status === 'success') {
        user.provider === 'password' ? navigate('/SignUp') : navigate('/Login');
      } else {
        issueMsg(data.message);
      }
    } catch (error) {
      issueMsg(t('HomeChild.errorDeletingAccount'));
    }
  };

  return (
    <div className="modal">
      <div className="modal__content">
        <p>{t('HomeChild.unsubscribeConfirmation')}</p>
        <div className="modal__buttons">
          <button className="btn" type="button" onClick={handleDelUserClick}>
            {t('HomeChild.unsubscribeButton')}
          </button>
          <button className="btn" type="button" onClick={() => setWithdrawalModalOpen(false)}>
            {t('HomeChild.cancelUnsubscribeButton')}
          </button>
        </div>
      </div>
    </div>
  );
};

// Component for changing the user's password
export const HomePasswordChangeModal = ({ setPasswordChangeModalOpen, user }) => {
  const { t } = useTranslation("global");

  const handleChangePassword = async (e) => {
    e.preventDefault();
    const oldPassword = e.target.oldPassword.value;
    const newPassword = e.target.newPassword.value;
    const sameNewPassword = e.target.sameNewPassword.value;

    if (!oldPassword) {
      issueMsg(t('HomeChild.previousPasswordNotEntered'));
      return;
    } else if (!newPassword) {
      issueMsg(t('HomeChild.newPasswordNotEntered'));
      return;
    } else if (!sameNewPassword) {
      issueMsg(t('HomeChild.newPasswordConfirmationMissing'));
      return;
    }
    if (newPassword !== sameNewPassword) {
      issueMsg(t('HomeChild.passwordMismatch'));
      return;
    }

    try {
      const response = await fetch('/backend/auth/changePassword.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: user.email,
          oldPassword: oldPassword,
          newPassword: newPassword,
        }),
      });

      const data = await response.json();

      if (data.status === 'success') {
        issueMsg(t('HomeChild.passwordUpdatedSuccessfully'));
        setPasswordChangeModalOpen(false);
      } else {
        issueMsg(data.message);
      }
    } catch (error) {
      issueMsg(t('HomeChild.errorUpdatingPassword'));
    }
  };

  return (
    <div className="modal">
      <div className="modal__content">
        <form onSubmit={handleChangePassword}>
          <div>
            <label htmlFor="oldPassword">
              {t('HomeChild.currentPassword')}
              <input type="password" name="oldPassword" id="oldPassword" />
            </label>
          </div>
          <div>
            <label htmlFor="newPassword">
              {t('HomeChild.newPassword')}
              <input type="password" name="newPassword" id="newPassword" />
            </label>
          </div>
          <div>
            <label htmlFor="sameNewPassword">
              {t('HomeChild.confirmNewPassword')}
              <input type="password" name="sameNewPassword" id="sameNewPassword" />
            </label>
          </div>
          <div className="modal__buttons">
            <button className="btn" type="submit">
              {t('HomeChild.changePasswordButton')}
            </button>
            <button className="btn" type="button" onClick={() => setPasswordChangeModalOpen(false)}>
              {t('HomeChild.cancelButton')}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
