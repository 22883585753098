 
import React from 'react';
import { useEffect, useState } from 'react';
import { initializeApp, getApps } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import './App.css';
import Home_page from "./pages/Home_page.js";
import { FaWhatsapp } from "react-icons/fa6";
import Footer from './components/Footer.js';
import Footersection from './components/Footersection.js';
import Navbare from './components/Navbare';
import SignUp from './components/SignUp';
import Home from './components/Home';
import Login from './components/Login';
import Solutions from './components/Solutions';
import Automation from './components/Automation';
import Safety from './components/Safety';
import Security from './components/Security';
import Buying_guide from './components/Buying_guide';
import Women from './Women.js';
import Connectivity from './components/Connectivity';
import Support from './components/Support';
import Contact_Us from './components/Contact_Us';
import LatestNews from './components/LatestNews';
import Cabel from './components/Cabel';
import Phone from './components/Phone';
import Controlunit from './components/Controlunit';
import Article from './components/Article';
import Ourclient from './components/Ourclient';
import Details from './Details';
import SignUpPage from './SignUpPage';
import Services from './Services';
import LogForgetpassword from './components/LogForgetpassword';
import UserInfoPage from './components/UserInfoPage';
import DetailsAdd from './components/DetailsAdd';
import DetailsAddOnServices from './components/DetailsAddOnServices';
import DocumentYourContract from './components/DocumentYourContract';
import HospitalServiceAdd from './components/HospitalServiceAdd.js';
import Service_provider from './components/Service_provider.js';
import Pdfuser from './components/Pdfuser.js';
import Service_Details from './components/Service_Details.js';
import ServiceAdd_Details from './components/ServiceAdd_Details.js';
import MYcontracts from './components/MYcontracts.js';
import PersonalInfoForm from './components/PersonalInfoForm.js';
import SendMessage from './components/SendMessage.js';
import Our_Partner from './components/Our_Partner.js';
import Payment from './components/Payment';
import Privacy from './components/Privacy';
import Authorization_letter from './components/Authorization_letter.js';
import Contract_provisions from './components/Contract_provisions';
import Terms_and_conditions from './components/Terms_and_conditions';
import Privacy_policy from './components/Privacy_policy.js';
import My_appointments from './components/My_appointments.js';
import My_contracts from './components/My_contracts.js';
import Establishment_Services_assistance from './components/Establishment_Services_assistance.js';
import Est_Services from './components/Est_Services.js';
import Job_applications from './components/Job_applications.js';
import Calendar from './components/Calendar.js';
import { useAuthContext } from './context/AuthContext';
import splashGif from './assets/lahaandlak.gif';
import '@fortawesome/fontawesome-free/css/all.css';
import { AuthProvider } from "./context/AuthContext";
import VisitorLogger from './components/VisitorLogger';
import Verify from './components/Verify.js';
import VerifyOTP from './components/VerifyOTP.js';
import Gorfe from './components/Gorfe.js';

const call = 'https://api.whatsapp.com/send?phone=+966920014308&text=Hello!';

function AppContent() {
  const { user } = useAuthContext();

  return (
    <BrowserRouter>
      <Navbare />

      <VisitorLogger />

      <div className="fixed-button">
        <button>
          <a className="text-white" href={call}>
            <FaWhatsapp size={30} />
          </a>
        </button>
      </div>
      <Routes>
        <Route path="/" element={<Services />} />
        <Route path="/women_services" element={<Women />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/login" element={<Login />} />
        <Route path="/contact_us" element={<Contact_Us />} />
        <Route path="/SendMessage" element={<SendMessage />} />
        <Route path="/solutions" element={<Solutions />} />
        <Route path="/automation" element={<Automation />} />
        <Route path="/safety" element={<Safety />} />
        <Route path="/connectivity" element={<Connectivity />} />
        <Route path="/support" element={<Support />} />
        <Route path="/Latestnews" element={<LatestNews />} />
        <Route path="/cabel" element={<Cabel />} />
        <Route path="/phone" element={<Phone />} />
        <Route path="/controlunit" element={<Controlunit />} />
        <Route path="/article" element={<Article />} />
        <Route path="/details" element={<Details />} />
        <Route path="/ourclient" element={<Ourclient />} />
        <Route path="/forget" element={<LogForgetpassword />} />
        <Route path="/user-info" element={<UserInfoPage />} />
        <Route path="/Pdfuser" element={<Pdfuser />} /> 
        <Route path="/Service_providers" element={<Security />} />
        <Route path="/Buying_guide" element={<Buying_guide />} />
        <Route path="/DetailsAdd" element={<DetailsAdd />} />
        <Route path="/HospitalServiceAdd" element={<HospitalServiceAdd />} />
        <Route path="/Service_Details" element={<Service_Details />} />
        <Route path="/Document_Your_Contract" element={<DocumentYourContract />} />
        <Route path="/contracts" element={<DocumentYourContract />} />
        <Route path="/home" element={<Home />} />
        <Route path="/My_appointments" element={<My_appointments />} />
        <Route path="/My_contracts" element={<My_contracts />} />
        <Route path="/MYcontracts" element={<MYcontracts />} />
        <Route path="/PersonalInfoForm" element={<PersonalInfoForm />} />
        <Route path="/Payment" element={<Payment />} />
        <Route path="/Contract_provisions" element={<Contract_provisions />} />
        <Route path="/Privacy" element={<Privacy />} />
        <Route path="/ServiceAdd_Details" element={<ServiceAdd_Details />} />
        <Route path="/verify" element={<Verify />} />
        <Route path="/Terms_and_conditions" element={<Terms_and_conditions />} />
        <Route path="/privacy_policy" element={<Privacy_policy />} />
        <Route path="/calendar" element={<Calendar />} />
        <Route path="/user-info" element={<UserInfoPage />} />
        <Route path="/DetailsAddOnServices" element={<DetailsAddOnServices />} />
        <Route path="/Service_provider" element={<Service_provider />} />
        <Route path="/Job_applications" element={<Job_applications />} />
        <Route path="/verify-otp" element={<VerifyOTP />} />
        <Route path="/Home_page" element={<Home_page />} />
        <Route path="/Our_Partner" element={<Our_Partner />} />
        <Route path="/Establishment_Services_assistance" element={<Establishment_Services_assistance />} />
        <Route path="/Est_Services" element={<Est_Services />} />
        <Route path="/ServiceAdd_Details" element={<ServiceAdd_Details />} />
        <Route path="/Footersection" element={<Footersection />} />
        <Route path="/Authorization_letter" element={<Authorization_letter />} />
        <Route path="*" element={<div><h1>404 Page not found.</h1></div>} />
      </Routes>
      <br />
      <Footersection />

      <Footer />
    </BrowserRouter>
  );
}

function App() {
  useEffect(() => {
    if (!getApps().length) {
      const app = initializeApp({
        apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
        authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
        projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
        storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
        messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
        appId: process.env.REACT_APP_FIREBASE_APP_ID,
        measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
      });
      const analytics = getAnalytics(app);
    }
  }, []);
  return (
    <AuthProvider>
      <AppContent />
    </AuthProvider>
  );
}

export default App;






























// import React, { useState } from "react";
// import "./Services.css"; // Ensure you include the relevant CSS file

// const ButtonGroup = () => {
  // const [selectedButton, setSelectedButton] = useState(null); // Tracks the currently selected button

//   const handleButtonClick = (button) => {
//     setSelectedButton(button); // Update the selected button state
//   };

//   return (
//     <div className="categories-wrapper">
//       <button
//         className={`category-btn ${
//           selectedButton === "button1" ? "active" : ""
//         }`}
//         onClick={() => handleButtonClick("button1")}
//       >
//         Button 1
//       </button>
//       <button
//         className={`category-btn ${
//           selectedButton === "button2" ? "active" : ""
//         }`}
//         onClick={() => handleButtonClick("button2")}
//       >
//         Button 2
//       </button>
//       <button
//         className={`category-btn ${
//           selectedButton === "button3" ? "active" : ""
//         }`}
//         onClick={() => handleButtonClick("button3")}
//       >
//         Button 3
//       </button>
//     </div>
//   );
// };

// export default ButtonGroup;











// import React, { useState, useEffect } from "react";

// const App = () => {
//   const [selectedFilters, setSelectedFilters] = useState(() => {
//     const savedFilters = localStorage.getItem("selectedFilters");
//     return savedFilters
//       ? JSON.parse(savedFilters)
//       : {
//           cost_type: [],  // Initialize cost_type as an empty array
//           medical_procedure: [],  // Initialize medical_procedure as an empty array
//         };
//   });

//   // State to store a message when filters are updated
// const [message, setMessage] = useState("");

//   // State to store the fetched data from the backend
// const [serviceData, setServiceData] = useState([]);

//   // Log the selected filters
//   console.log("Selected Filters: ", selectedFilters);

//   // Function to handle checkbox changes and apply filters automatically
//   const handleCheckboxChange = (filterCategory, value) => {
//     const updatedFilters = { ...selectedFilters };
//     const filterValues = updatedFilters[filterCategory] || [];

//     if (filterValues.includes(value)) {
//       updatedFilters[filterCategory] = filterValues.filter(
//         (item) => item !== value
//       );
//     } else {
//       updatedFilters[filterCategory] = [...filterValues, value];
//     }

//     setSelectedFilters(updatedFilters);
//     localStorage.setItem("selectedFilters", JSON.stringify(updatedFilters)); // Store updated filters in localStorage
//     setMessage("Filters updated!"); // Set a message when a filter is updated

//     // Log the updated filters
//     console.log("Updated Filters after change: ", updatedFilters);
//   };

//   // Function to update URL query parameters and fetch data from API
//   const updateURL = () => {
//     const queryParams = [];

//     // Loop through selected filters and add to query string if any values exist
//     Object.keys(selectedFilters).forEach((filter) => {
//       if (selectedFilters[filter].length > 0) {
//         queryParams.push(`${filter}=${selectedFilters[filter].join(",")}`);
//       }
//     });

//     // Construct query string
//     const queryString = queryParams.join("&");

//     // Log the query string
//     console.log("Constructed Query String:", queryString);

//     // Update the local URL without reloading the page
//     const newURL = `${window.location.pathname}?${queryString}`;
//     console.log("Updated local URL: ", newURL);
//     window.history.replaceState(null, "", newURL);

//     // API URL construction
//     const apiUrl = `http://lahalak.sa/backend/getAdditionalServices/filtred.php?${queryString}`;
//     console.log("Constructed API URL: ", apiUrl);  // Log the constructed API URL

//     // Fetch data from the PHP API
//     fetch(apiUrl, {
//       mode: 'cors',  // Ensures CORS requests
//     })
//       .then((response) => {
//         console.log("Fetch Response Status: ", response.status);  // Log the response status
//         return response.json();
//       })
//       .then((data) => {
//         console.log("Fetched Data: ", data);  // Log the fetched data
//         setServiceData(data); // Store the fetched data in the state
//       })
//       .catch((error) => {
//         console.error("Error fetching data: ", error);  // Log any errors
//         setMessage("Error fetching data");
//       });
//   };


//   // Trigger the updateURL function whenever selectedFilters change
//   useEffect(() => {
//     updateURL();
//   }, [selectedFilters]);

//   // Function to display selected filters in a user-friendly way
//   const renderSelectedFilters = () => {
//     const activeFilters = Object.keys(selectedFilters).filter(
//       (filter) => selectedFilters[filter].length > 0
//     );
//     if (activeFilters.length === 0) {
//       return <p>No filters selected.</p>;
//     }
//     return (
//       <ul>
//         {activeFilters.map((filter) => (
//           <li key={filter}>
//             {filter}: {selectedFilters[filter].join(", ")}
//           </li>
//         ))}
//       </ul>
//     );
//   };

//   // Function to display the fetched service data
//   const renderServiceData = () => {
//     if (serviceData.length === 0) {
//       return <p>No data available</p>;
//     }
//     return (
//       <div>
//         {serviceData.map((service) => (
//           <div key={service.id} className="service-item">
//             <h3>{service.title}</h3>
//             <p>
//               <strong>Summary:</strong> {service.summary}
//             </p>
//             <p>
//               <strong>Description:</strong> {service.description}
//             </p>
//             <p>
//               <strong>Category:</strong> {service.category}
//             </p>
//             <p>
//               <strong>Included Services:</strong> {service.included_services}
//             </p>
//             <p>
//               <strong>Special Conditions:</strong> {service.special_conditions}
//             </p>
//             <hr />
//           </div>
//         ))}
//       </div>
//     );
//   };

//   return (
//     <div>
//       <h2>Select Filters</h2>

//       <div>
//         <h3>Cost Type</h3>
//         <label>
//           <input
//             type="checkbox"
//             checked={selectedFilters.cost_type?.includes("فلوس")}
//             onChange={() => handleCheckboxChange("cost_type", "فلوس")}
//           />
//           فلوس
//         </label>
//         <label>
//           <input
//             type="checkbox"
//             checked={selectedFilters.cost_type?.includes("وقت")}
//             onChange={() => handleCheckboxChange("cost_type", "وقت")}
//           />
//           وقت
//         </label>
//       </div>

//       <div>
//         <h3>Medical Procedure</h3>
//         <label>
//           <input
//             type="checkbox"
//             checked={selectedFilters.medical_procedure?.includes("ضغط")}
//             onChange={() => handleCheckboxChange("medical_procedure", "ضغط")}
//           />
//           ضغط
//         </label>
//         <label>
//           <input
//             type="checkbox"
//             checked={selectedFilters.medical_procedure?.includes("سكري")}
//             onChange={() => handleCheckboxChange("medical_procedure", "سكري")}
//           />
//           سكري
//         </label>
//       </div>

//       {message && <p>{message}</p>}

//       <h3>Currently Selected Filters:</h3>
//       {renderSelectedFilters()}

//       <h3>Fetched Services:</h3>
//       {renderServiceData()}
//     </div>
//   );
// };

// export default App;











































// import React, { useState, useEffect } from "react";

// const App = () => {
//   const [serviceData, setServiceData] = useState([]); // State to store fetched service data
//   const [message, setMessage] = useState(""); // State to store any error message

//   const [selectedFilters, setSelectedFilters] = useState({
//     pressure: false, // ضغط
//     diabetes: false, // سكري
//     Obesity: false, // سمنة
//     male: false, // ذكر
//     female: false, // أنثى
//     family: false, // عائلة
//     teenager: false, // ذكر
//     adult: false, // أنثى
//     senior: false, // عائلة
//     infant: false, // عائلة
//     child: false, // عائلة
//     home: false,
//     hospital: false,

//     prevention: false, // عائلة
//     diagnosis: false, // عائلة
//     treatment: false,
//     care: false,


//     generation: false, // عائلة
//     endoscope: false, // عائلة
//     operation: false,
//     analysis: false,
//     rays: false, // عائلة
//     detection: false, // عائلة
//     medium: false, // متوسطة
//     permanent: false, // دائمة
//     oneTime: false, // مرة واحدة

    // specialiste2: '',
    // specialiste1: '',


//   });

//   // Function to fetch data from the backend based on selected filters
//   const fetchData = () => {
//     const selectedMedicalProcedures = [];
//     const selectedGenders = [];
//     const selectedage_group = [];
//     const service_location = [];
//     const treatment_stage = [];
//     const procedure_type = [];
//     const service_duration = [];
//     const cost_type = [];

//     // Gather checkboxes filter values
//     if (selectedFilters.pressure) selectedMedicalProcedures.push("ضغط");
//     if (selectedFilters.diabetes) selectedMedicalProcedures.push("سكري");
//     if (selectedFilters.Obesity) selectedMedicalProcedures.push("سمنة");

//     if (selectedFilters.male) selectedGenders.push("ذكر");
//     if (selectedFilters.female) selectedGenders.push("أنثى");
//     if (selectedFilters.teenager) selectedage_group.push("مراهق");
//     if (selectedFilters.adult) selectedage_group.push("بالغ");
//     if (selectedFilters.senior) selectedage_group.push("كبار السن");
//     if (selectedFilters.infant) selectedage_group.push("رضيع");
//     if (selectedFilters.child) selectedage_group.push("طفل");

//     if (selectedFilters.home) service_location.push("في المنزل");
//     if (selectedFilters.hospital) service_location.push("في المستشفى");

//     if (selectedFilters.prevention) treatment_stage.push("وقاية");
//     if (selectedFilters.diagnosis) treatment_stage.push("تشخيص");
//     if (selectedFilters.treatment) treatment_stage.push("علاج");
//     if (selectedFilters.care) treatment_stage.push("رعاية");

//     if (selectedFilters.generation) procedure_type.push("توليد");
//     if (selectedFilters.endoscope) procedure_type.push("منظار");
//     if (selectedFilters.operation) procedure_type.push("عملية");
//     if (selectedFilters.analysis) procedure_type.push("تحليل");
//     if (selectedFilters.rays) procedure_type.push("أشعة");
//     if (selectedFilters.detection) procedure_type.push("كشف");

//     if (selectedFilters.medium) service_duration.push("متوسطة");
//     if (selectedFilters.permanent) service_duration.push("دائمة");
//     if (selectedFilters.oneTime) service_duration.push("مرة واحدة");

//     if (selectedFilters.timeCost) cost_type.push("وقت");
//     if (selectedFilters.moneyCost) cost_type.push("فلوس");
//     if (selectedFilters.bothCost) cost_type.push("الإثنان");

//     // Build the query string
//     const queryString = new URLSearchParams();

//     if (selectedMedicalProcedures.length > 0) {
//       queryString.append("medical_procedure", selectedMedicalProcedures.join("-"));
//     }

//     if (selectedGenders.length > 0) {
//       queryString.append("gender", selectedGenders.join("-"));
//     }

//     if (selectedage_group.length > 0) {
//       queryString.append("age_group", selectedage_group.join("-"));
//     }

//     if (service_location.length > 0) {
//       queryString.append("service_location", service_location.join("-"));
//     }

//     if (treatment_stage.length > 0) {
//       queryString.append("treatment_stage", treatment_stage.join("-"));
//     }

//     if (procedure_type.length > 0) {
//       queryString.append("procedure_type", procedure_type.join("-"));
//     }

//     if (service_duration.length > 0) {
//       queryString.append("service_duration", service_duration.join("-"));
//     }

//     if (cost_type.length > 0) {
//       queryString.append("cost_type", cost_type.join("-"));
//     }

    // // Add Specialiste1 and Specialiste2 if available
    // if (selectedFilters.specialiste1) {
    //   queryString.append("specialiste1", selectedFilters.specialiste1);
    // }

    // if (selectedFilters.specialiste2) {
    //   queryString.append("specialiste2", selectedFilters.specialiste2);  // Automatically encodes the comma
    // }


//     const apiUrl = `https://lahalak.sa/backend/getAdditionalServices/filtred.php?${queryString.toString()}`;

//     console.log("Fetching data from: ", apiUrl);

//     fetch(apiUrl, { mode: "cors" })
//       .then((response) => {
//         if (!response.ok) {
//           throw new Error(`HTTP error! status: ${response.status}`);
//         }
//         return response.json();
//       })
//       .then((data) => {
//         console.log("Fetched Data: ", data);

//         if (Array.isArray(data)) {
//           setServiceData(data); // Store the fetched data in the state
//           setMessage(""); // Clear any error message
//         } else {
//           setMessage(data.message || "No results found");
//           setServiceData([]);
//         }
//       })
//       .catch((error) => {
//         console.error("Error fetching data: ", error);
//         setMessage("Error fetching data");
//       });

//     // Update the browser URL with the query string without reloading the page
//     const newURL = `${window.location.pathname}?${queryString.toString()}`;
//     window.history.replaceState(null, "", newURL);
//   };


//   // Fetch data whenever selected filters change
//   useEffect(() => {
//     fetchData();
//   }, [selectedFilters]);
//   useEffect(() => {
//     if (selectedFilters.specialiste2) {
//       fetchData();
//     }
//   }, [selectedFilters]);

//   // Function to handle button clicks
//   const handleButtonClick = (specialiste1Value, specialiste2Value) => {
//     // Handle the specialiste2 value with the plus sign
//     const processedSpecialiste2 = specialiste2Value ? specialiste2Value.replace(/\+/g, ',') : null;

//     setSelectedFilters((prevState) => ({
//       ...prevState,
//       specialiste1: specialiste1Value || prevState.specialiste1,  // If you need to set or update Specialiste1
//       specialiste2: processedSpecialiste2 || prevState.specialiste2,  // Set or update Specialiste2 after replacing the plus sign
//     }));

//     fetchData();  // Trigger data fetch immediately after setting the values
//   };


//   const handleCheckboxChange = (filter) => {
//     setSelectedFilters((prevState) => ({
//       ...prevState,
//       [filter]: !prevState[filter], // Toggle the value of the selected filter
//     }));
//     fetchData();  // Re-fetch data whenever a checkbox is clicked
//   };


//   // Function to render the fetched service data
//   const renderServiceData = () => {
//     if (serviceData.length === 0) {
//       return <p>{message || "No data available"}</p>;
//     }
//     const renderServiceData = () => {
//       if (serviceData.length === 0) {
//         return <p>{message || "No data available"}</p>;
//       }
//       return (
//         <div>
//           {serviceData.map((service) => (
//             <div key={service.id} className="service-item">
//               <h3>{service.title}</h3>
//               <p><strong>Summary:</strong> {service.summary}</p>
//               <p><strong>Description:</strong> {service.description}</p>
//               <p><strong>Category:</strong> {service.category}</p>
//               <p><strong>Included Services:</strong> {service.included_services}</p>
//               <p><strong>Special Conditions:</strong> {service.special_conditions}</p>
//               <hr />
//             </div>
//           ))}
//         </div>
//       );
//     };

//   };

//   return (
//     <div>
//       <h2>Select Medical Procedure Filters</h2>

//       <label>
//         <input
//           type="checkbox"
//           checked={selectedFilters.pressure}
//           onChange={() => handleCheckboxChange("pressure")}
//         />
//         ضغط (Blood Pressure)
//       </label>

//       <label>
//         <input
//           type="checkbox"
//           checked={selectedFilters.diabetes}
//           onChange={() => handleCheckboxChange("diabetes")}
//         />
//         (Diabetes)
//       </label>
//       <label>
//         <input
//           type="checkbox"
//           checked={selectedFilters.Obesity}
//           onChange={() => handleCheckboxChange("Obesity")}
//         />
//         (Obesity)
//       </label>

//       <h2>Select Gender Filters</h2>

//       <label>
//         <input
//           type="checkbox"
//           checked={selectedFilters.male}
//           onChange={() => handleCheckboxChange("male")}
//         />
//         ذكر (Male)
//       </label>

//       <label>
//         <input
//           type="checkbox"
//           checked={selectedFilters.female}
//           onChange={() => handleCheckboxChange("female")}
//         />
//         أنثى (Female)
//       </label>



//       <h2>Select Gender Filters</h2>

//       <label>
//         <input
//           type="checkbox"
//           checked={selectedFilters.senior}
//           onChange={() => handleCheckboxChange("senior")}
//         />
//         طفل (senior)
//       </label>

//       <label>
//         <input
//           type="checkbox"
//           checked={selectedFilters.teenager}
//           onChange={() => handleCheckboxChange("teenager")}
//         />
//         مراهق (teenager)
//       </label>
//       <label>
//         <input
//           type="checkbox"
//           checked={selectedFilters.adult}
//           onChange={() => handleCheckboxChange("adult")}
//         />
//         بالغ (adult)
//       </label>



//       <label>
//         <input
//           type="checkbox"
//           checked={selectedFilters.infant}
//           onChange={() => handleCheckboxChange("infant")}
//         />
//         رضيع (infant)
//       </label>


//       <label>
//         <input
//           type="checkbox"
//           checked={selectedFilters.child}
//           onChange={() => handleCheckboxChange("child")}
//         />
//         كبار السن (child)
//       </label>
//       <h2>Select Gender Filters</h2>

//       <label>
//         <input
//           type="checkbox"
//           checked={selectedFilters.home}
//           onChange={() => handleCheckboxChange("home")}
//         />
//         في المنزل(home)
//       </label>
//       <label>
//         <input
//           type="checkbox"
//           checked={selectedFilters.hospital}
//           onChange={() => handleCheckboxChange("hospital")}
//         />
//         في المستشفى (hospital)
//       </label>

//       <h2>Select   Filters</h2>

//       <label>
//         <input
//           type="checkbox"
//           checked={selectedFilters.prevention}
//           onChange={() => handleCheckboxChange("prevention")}
//         />
//         وقاية (prevention)
//       </label>
//       <label>
//         <input
//           type="checkbox"
//           checked={selectedFilters.diagnosis}
//           onChange={() => handleCheckboxChange("diagnosis")}
//         />
//         تشخيص (diagnosis)
//       </label>
//       <label>
//         <input
//           type="checkbox"
//           checked={selectedFilters.treatment}
//           onChange={() => handleCheckboxChange("treatment")}
//         />
//         علاج (treatment)
//       </label>
//       <label>
//         <input
//           type="checkbox"
//           checked={selectedFilters.care}
//           onChange={() => handleCheckboxChange("care")}
//         />
//         رعاية (care)
//       </label>


//       <h2>Select   Filters</h2>

//       <label>
//         <input
//           type="checkbox"
//           checked={selectedFilters.generation}
//           onChange={() => handleCheckboxChange("generation")}
//         />
//         توليد (generation)
//       </label>
//       <label>
//         <input
//           type="checkbox"
//           checked={selectedFilters.endoscope}
//           onChange={() => handleCheckboxChange("endoscope")}
//         />
//         منظار (endoscope)
//       </label>
//       <label>
//         <input
//           type="checkbox"
//           checked={selectedFilters.operation}
//           onChange={() => handleCheckboxChange("operation")}
//         />
//         عملية (operation)
//       </label>
//       <label>
//         <input
//           type="checkbox"
//           checked={selectedFilters.analysis}
//           onChange={() => handleCheckboxChange("analysis")}
//         />
//         تحليل (analysis)
//       </label>
//       <label>
//         <input
//           type="checkbox"
//           checked={selectedFilters.rays}
//           onChange={() => handleCheckboxChange("rays")}
//         />
//         أشعة (rays)
//       </label>
//       <label>
//         <input
//           type="checkbox"
//           checked={selectedFilters.detection}
//           onChange={() => handleCheckboxChange("detection")}
//         />
//         كشف (detection)
//       </label>


//       <h2>Select Service Duration Filters</h2>
//       <label>
//         <input
//           type="checkbox"
//           checked={selectedFilters.medium}
//           onChange={() => handleCheckboxChange("medium")}
//         />
//         متوسطة (Medium)
//       </label>
//       <label>
//         <input
//           type="checkbox"
//           checked={selectedFilters.permanent}
//           onChange={() => handleCheckboxChange("permanent")}
//         />
//         دائمة (Permanent)
//       </label>
//       <label>
//         <input
//           type="checkbox"
//           checked={selectedFilters.oneTime}
//           onChange={() => handleCheckboxChange("oneTime")}
//         />
//         مرة واحدة (One-time)
//       </label>

//       <h2>Select Cost Type Filters</h2>
//       <label>
//         <input
//           type="checkbox"
//           checked={selectedFilters.timeCost}
//           onChange={() => handleCheckboxChange("timeCost")}
//         />
//         وقت (Time)
//       </label>
//       <label>
//         <input
//           type="checkbox"
//           checked={selectedFilters.moneyCost}
//           onChange={() => handleCheckboxChange("moneyCost")}
//         />
//         فلوس (Money)
//       </label>
//       <label>
//         <input
//           type="checkbox"
//           checked={selectedFilters.bothCost}
//           onChange={() => handleCheckboxChange("bothCost")}
//         />
//         الإثنان (Both)
//       </label>
//       <button className="Button" onClick={() => handleButtonClick(null, "أمراض-الكبد+أمراض-الكلى")}>
//         أمراض
//       </button>


//       <button
//         className="Button"
//         onClick={() =>
//           handleButtonClick(
//             "طب-النساء-والتوليد", // specialiste1 value
//             "طب-التوليد,طب-الخصوبة,أمراض-الجهاز-التناسلي,تنظيم-الأسرة,طب-الأورام-النسائية,جراحات-الجهاز-التناسلي,طب-سن-اليأس,علاج-العقم,تشخيص-وعلاج-الالتهابات-النسائية" // specialiste2 values
//           )
//         }
//       >
//         Women’s Health Specialties
//       </button>

//       <button
//         className="Button"
//         onClick={() =>
//           handleButtonClick(
//             "العلاج-النفسي", // Specialiste1 value for general psychological treatments
//             "اضطرابات-القلق,الاكتئاب,الفصام,اضطرابات-الأكل,اضطرابات-النوم,الاضطرابات-ثنائية-القطب,اضطرابات-الوسواس-القهري,اضطراب-ما-بعد-الصدمة,إدمان-المخدرات-والكحول,الاضطرابات-الشخصية,العلاج-النفسي" // Specialiste2 values for specific psychological conditions
//           )
//         }
//       >
//         العلاج النفسي
//       </button>

//       <button
//         className="Button"
//         onClick={() =>
//           handleButtonClick(
//             "العلاج-الطبيعي", // Specialiste1 value for general physical therapy
//             "العلاج-الطبيعي,العلاج-الوظيفي,إعادة-تأهيل-الإصابات-الرياضية,إعادة-التأهيل-القلبي,إعادة-تأهيل-السكتة-الدماغية,العلاج-بالماء,إعادة-تأهيل-العمود-الفقري,إعادة-تأهيل-ما-بعد-العمليات-الجراحية,العلاج-بالكهرباء,العلاج-الوظيفي-للأطراف-الصناعية" // Specialiste2 values for specific physical therapy sub-specializations
//           )
//         }
//       >
//         العلاج الطبيعي (Physical Therapy)
//       </button>
//       <button
//         className="Button"
//         onClick={() =>
//           handleButtonClick(
//             "التحاليل", // Specialiste1 value for general laboratory tests
//             "التحاليل-المخبرية,التشخيص-الجيني,علم-الأمراض-التشريحي" // Specialiste2 values for specific sub-specializations
//           )
//         }
//       >
//         التحاليل (Laboratory Tests)
//       </button>
//       <button
//         className="Button"
//         onClick={() =>
//           handleButtonClick(
//             "أمراض-الذكورة", // Specialiste1 value for male reproductive health
//             "طب-الخصوبة,أمراض-الجهاز-التناسلي,علاج-العقم" // Specialiste2 values for specific sub-specializations
//           )
//         }
//       >
//         أمراض الذكورة (Male Reproductive Health)
//       </button>
//       <button
//         className="Button"
//         onClick={() =>
//           handleButtonClick(
//             "طب-منزلي", // Specialiste1 value for home medicine
//             "الصحة-العامة,الفحص-المبكر,التحاليل-المخبرية,الطب-الطبيعي-وإعادة-التأهيل,العلاج-الدوائي,العلاج-الطبيعي,العلاج-الوظيفي,العناية-المركزة-للأطفال,طب-حديثي-الولادة" // Specialiste2 values for specific sub-specializations
//           )
//         }
//       >
//         طب منزلي
//       </button>

//       {message && <p>{message}</p>}

//       <h3>Fetched Services (Filtered)</h3>
//       {/* Render the fetched service data */}
//       {renderServiceData()}
//     </div>
//   );
// };

// export default App;





























// import React, { useEffect, useState } from 'react';
// import './App.css';

// function App() {
//   const [services, setServices] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const [selectedAgeGroups, setSelectedAgeGroups] = useState([]);
//   const [selectedGenders, setSelectedGenders] = useState([]);
//   const [activeScreen, setActiveScreen] = useState('first'); // State to toggle between screens

//   // Define the age groups and genders
//   const ageGroups = ['مراهق', 'بالغ', 'كبار السن', 'رضيع', 'طفل'];
//   const genders = ['أنثى', 'ذكر'];

//   useEffect(() => {
//     // Determine which URL to fetch based on the active screen
//     let url = activeScreen === 'first'
//       ? 'https://lahalak.sa/backend/getAdditionalServices/servicewomenget.php'
//       : 'https://lahalak.sa/backend/getAdditionalServices/pregnancy_follow_up.php';

//     setLoading(true); // Start loading state

//     fetch(url)
//       .then(response => {
//         if (!response.ok) {
//           throw new Error('Network response was not ok');
//         }
//         return response.json();
//       })
//       .then(data => {
//         setServices(data);
//         setLoading(false); // Stop loading when data is fetched
//       })
//       .catch(error => {
//         setError(error);
//         setLoading(false); // Stop loading when there's an error
//       });
//   }, [activeScreen]); // Trigger the effect when activeScreen changes

//   const handleAgeGroupChange = (e) => {
//     const { value, checked } = e.target;
//     setSelectedAgeGroups(prevSelected =>
//       checked ? [...prevSelected, value] : prevSelected.filter(age => age !== value)
//     );
//   };

//   const handleGenderChange = (e) => {
//     const { value, checked } = e.target;
//     setSelectedGenders(prevSelected =>
//       checked ? [...prevSelected, value] : prevSelected.filter(gender => gender !== value)
//     );
//   };

//   const filterServices = () => {
//     return services.filter(service => {
//       const filters = service.filters ? JSON.parse(service.filters) : {};
      
//       const matchesAgeGroup = selectedAgeGroups.length === 0 ||
//         (filters.age_group && filters.age_group.some(age => selectedAgeGroups.includes(age)));

//       const matchesGender = selectedGenders.length === 0 ||
//         (filters.gender && filters.gender.some(gender => selectedGenders.includes(gender)));

//       return matchesAgeGroup && matchesGender;
//     });
//   };

//   if (loading) return <div>Loading...</div>;
//   if (error) return <div>Error: {error.message}</div>;

//   const filteredServices = filterServices();

//   return (
//     <div className="App">
//       <h1>Pregnancy Follow-Up Services</h1>

//       {/* Toggle between screens */}
//       <div>
//         <button onClick={() => setActiveScreen('first')}>Screen 1</button>
//         <button onClick={() => setActiveScreen('second')}>Screen 2</button>
//       </div>

//       {/* Checkbox Filters for Age Groups */}
//       <div className="filters">
//         <h3>Filter by Age Group:</h3>
//         {ageGroups.map(ageGroup => (
//           <label key={ageGroup}>
//             <input
//               type="checkbox"
//               value={ageGroup}
//               onChange={handleAgeGroupChange}
//             />
//             {ageGroup}
//           </label>
//         ))}
//       </div>

//       {/* Checkbox Filters for Gender */}
//       <div className="filters">
//         <h3>Filter by Gender:</h3>
//         {genders.map(gender => (
//           <label key={gender}>
//             <input
//               type="checkbox"
//               value={gender}
//               onChange={handleGenderChange}
//             />
//             {gender}
//           </label>
//         ))}
//       </div>

//       {/* Display Filtered Services */}
//       <ul>
//         {filteredServices.length > 0 ? (
//           filteredServices.map(service => (
//             <li key={service.id}>
//               <h2>{service.title}</h2>
//             </li>
//           ))
//         ) : (
//           <p>No services available based on the selected filters.</p>
//         )}
//       </ul>
//     </div>
//   );
// }

// export default App;
