// VerifyOTP.js
import React, { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Input, Button, Typography, Card, CardContent } from "@mui/material";
import { useTranslation } from "react-i18next";

const VerifyOTP = () => {
  const [otp, setOtp] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [searchParams] = useSearchParams();
  const email = searchParams.get("email"); // Get email from URL params
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(`/backend/auth/verify_otp.php`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: new URLSearchParams({ otp, email }),
      });

      const result = await response.json();
      if (result.status === 'success') {
        setResponseMessage(t('OTP verified successfully!'));
        setTimeout(() => navigate("/home"), 2000); // Redirect to home after OTP success
      } else {
        setResponseMessage(t('OTP verification failed!'));
      }
    } catch (error) {
      setResponseMessage(t('Error occurred during verification!'));
    }
  };

  return (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
      <Card>
        <CardContent>
          <Typography variant="h5" component="h2">
            {t("Verify OTP")}
          </Typography>
          <form onSubmit={handleSubmit} style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
            <Input
              type="text"
              placeholder="Enter OTP"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              required
            />
            <Button type="submit" variant="contained">
              {t("Verify")}
            </Button>
          </form>
          {responseMessage && <Typography color={responseMessage.includes("success") ? "green" : "red"}>{responseMessage}</Typography>}
        </CardContent>
      </Card>
    </div>
  );
};

export default VerifyOTP;
